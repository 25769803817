import { useContext } from "react";
import { useParams } from "react-router";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import { Context } from "../context/DataContext";
import { FaHandHoldingUsd } from "react-icons/fa";
import DebtorNameHeader from "@shared/components/DebtorNameHeader";
import RenderDebtorLoans from "@shared/components/RenderDebtorLoans";
import DebtorMenu from "@shared/components/DebtorMenu";
import Loading from "@shared/components/Loading";
import { UseDebtor, UseDebtorPaidLoans } from "@shared/server/fetcher";

const DebtorPaidLoans = ({ history }) => {
  const { id: debtor_id } = useParams();
  const { state } = useContext(Context);
  const { user } = state;

  const { data: debtor, isLoading: isLoadingDebtor } = UseDebtor(debtor_id, user.user_id);
  const { data: loans, isLoading: isLoadingLoans } = UseDebtorPaidLoans(debtor_id, user.user_id);

  if (isLoadingDebtor) return <Loading />;

  return (
    <div className="mt-2 mb-5">
      <Container maxWidth="md" disableGutters>
        <DebtorNameHeader debtor={debtor}>
          <DebtorMenu history={history} debtor={debtor} profile />
        </DebtorNameHeader>

        {loans?.length === 0 && (
          <div className="container">
            <Box component="div" paddingBottom={2}>
              <Typography variant="h6" component="p">
                No prestamos saldado encontrado
              </Typography>

              <Typography variant="body1" component="p">
                No prestamos previamente saldado fueron encotrado!
              </Typography>
            </Box>

            <Box paddingBottom={2}>
              <Button
                fullWidth
                startIcon={<FaHandHoldingUsd size="1.2em" />}
                variant="outlined"
                color="default"
                onClick={() => history.push("/loan/create/" + debtor.debtor_id)}>
                Nuevo Prestamo!
              </Button>
            </Box>
          </div>
        )}

        {isLoadingLoans ? (
          <Loading />
        ) : (
          <Box component="div" p={1}>
            <RenderDebtorLoans title="Prestamos Saldado o Borrado" history={history} loans={loans} />
          </Box>
        )}
      </Container>
    </div>
  );
};

export default DebtorPaidLoans;
