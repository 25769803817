import React from "react";
import { Children, useState, useContext, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import Stepper from "@material-ui/core/Stepper";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui/";
import { string, ref, object, mixed, addMethod } from "yup";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Context } from "../context/DataContext";
import { freeTrialsMonth } from "@shared/constants";
import { validateCedula } from "@shared/functions";

export default function SignUp({ history }) {
  addMethod(mixed, "validateCedula", validateCedula);
  const { state, setReferral, registerUser } = useContext(Context);
  const { invitationToken } = useParams();

  useEffect(() => {
    if (invitationToken) {
      setReferral({ invitationToken });
    }

    //eslint-disable-next-line
  }, [invitationToken]);

  return (
    <div className="container mt-4 mb-5">
      <Typography variant="h5" component="h3" gutterBottom>
        Registrarse!
      </Typography>
      <Typography variant="subtitle1" component="p" paragraph>
        Al registrarse, cuenta con un demo de prueba gratuito de {freeTrialsMonth} meces.
      </Typography>

      <FormikStepper
        initialValues={{
          invitationToken: state.invite && state.invite.invitationToken ? state.invite.invitationToken : null,
          token: state.device.token,
          fname: "",
          lname: "",
          phone: "",
          cedula: "",
          username: "",
          email: "",
          password: "",
          cpassword: "",
          parent_id: 0,
        }}
        onSubmit={async (values) => {
          await registerUser(values, history.replace);
        }}>
        <FormikStep
          label="Personal"
          validationSchema={object({
            fname: string()
              .required("Nombre es requerido!")
              .min(3, "Nombre es muy corto!")
              .matches(/^([^0-9]*)$/, "Nombre no puede tener numeros!"),
            lname: string()
              .required("Apellido es requerido!")
              .min(3, "Apellido es requerido!")
              .matches(/^([^0-9]*)$/, "Apellido no puede tener numeros!"),
            phone: string().required("Telefono es requerido").min(10, "Telefono invalido!"),
            cedula: mixed().validateCedula(true),
          })}>
          <Typography variant="subtitle1" component="p" paragraph>
            Por favor llene las siguientes casillas para crear su cuenta.
          </Typography>

          <Box paddingBottom={2}>
            <Field
              fullWidth
              autoComplete="firstName"
              inputProps={{
                maxLength: 20,
              }}
              name="fname"
              label="Nombre"
              component={TextField}
            />
          </Box>
          <Box paddingBottom={2}>
            <Field
              fullWidth
              autoComplete="lastName"
              inputProps={{
                maxLength: 30,
              }}
              name="lname"
              label="Apellido"
              component={TextField}
            />
          </Box>
          <Box paddingBottom={2}>
            <Field fullWidth autoComplete="phone" name="phone" label="Telefono" type="number" component={TextField} />
          </Box>
          <Box paddingBottom={5}>
            <Field fullWidth autoComplete="cedula" name="cedula" label="Cedula" type="tel" component={TextField} />
          </Box>
        </FormikStep>
        <FormikStep
          label="Usuario"
          validationSchema={object({
            username: string()
              .required("Usuario es requerido!")
              .min(4, "Su usuario debe ser minimos 4 letras/ numero!")
              .max(15, "Su usuario debe ser maximo 15 letras / numeros!")
              .matches(/^([a-zA-Z0-9_-]+)$/, "Su usuario solo puede tener letras y numeros!"),
            password: string()
              .required("Contraseña es requerida!")
              .min(7, "Su contraseña debe ser minimo 7 letras / numeros!")
              .max(25, "Su contraseña debe ser maximo 25 letras / numeros!"),
            cpassword: string()
              .required("Vuelva a ingresar la misma contraseña!")
              .oneOf([ref("password"), null], "Ingrese la misma contraseña 2 veces!"),
          })}>
          <Box paddingBottom={2}>
            <Typography variant="h6" component="h4" gutterBottom>
              Elija sus credenciales!
            </Typography>
          </Box>
          <Typography variant="subtitle1" component="p" paragraph className="text-info">
            <b>Por favor recuerde esta informacion para inciar session y nunca comparta esta informacion con nadie.</b>
          </Typography>

          <Box paddingBottom={2}>
            <Field
              fullWidth
              autoComplete="username"
              inputProps={{
                maxLength: 15,
              }}
              name="username"
              component={TextField}
              label="Usuario"
            />
          </Box>
          <Box paddingBottom={2}>
            <Field
              fullWidth
              autoComplete="password"
              type="password"
              inputProps={{
                maxLength: 30,
              }}
              name="password"
              component={TextField}
              label="Contraseña"
            />
          </Box>
          <Box paddingBottom={4}>
            <Field
              fullWidth
              autoComplete="confirm-password"
              type="password"
              inputProps={{
                maxLength: 30,
              }}
              name="cpassword"
              component={TextField}
              label="Confirmar Contraseña"
            />
          </Box>

          <Typography variant="subtitle1" component="p" paragraph className="text-danger">
            <b>
              Esta informacion es unicamente para due&ntilde;os de la ruta, no es para ser compartida con empleado
              (Cobradores)!
            </b>
          </Typography>
        </FormikStep>

        {/* <FormikStep label="Seguridad" validationSchema={object({})}>
            <Box paddingBottom={2}>
            <h6>Preguntas de Seguridad!</h6>
            </Box>
            <p>
            Por favor elija 3 preguntas de seguridad. Estas preguntas seran usada para recuperar su cuenta si algun
            dia olvida su contraña.
            </p>
            <Box paddingBottom={2}>
            <Field fullWidth name="routeName" label="Nombre de Ruta" component={TextField} />
            </Box>
            <Box paddingBottom={2}>
            <Field fullWidth name="routeLocation" label="Zona / Sector" component={TextField} />
            </Box>
            <Box paddingBottom={3}>
              <Field fullWidth name="routePhone" label="Telefono" component={TextField} />
              </Box>
              <Box paddingBottom={4}>
              <Field fullWidth name="routeCollectDay" label="Dia de Cobro" component={Select}>
              <MenuItem value={"Monday"}>Lunes</MenuItem>
              <MenuItem value={"Tuesday"}>Martes</MenuItem>
              <MenuItem value={"Wednesday"}>Miercoles</MenuItem>
              <MenuItem value={"Thursday"}>Jueves</MenuItem>
              <MenuItem value={"Friday"}>Viernes</MenuItem>
              <MenuItem value={"Saturday"}>Sabado</MenuItem>
              <MenuItem value={"Sunday"}>Domingo</MenuItem>
              </Field>
              </Box>
            </FormikStep> */}
      </FormikStepper>
    </div>
  );
}

export function FormikStep({ children }) {
  return <>{children}</>;
}

export function FormikStepper({ children, ...props }) {
  const childrenArray = Children.toArray(children);
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step];
  const [completed, setCompleted] = useState(false);
  function isLastStep() {
    return step === childrenArray.length - 1;
  }

  return (
    <>
      <Formik
        {...props}
        enableReinitialize
        validationSchema={currentChild.props.validationSchema}
        onSubmit={async (values, helpers) => {
          if (isLastStep()) {
            await props.onSubmit(values, helpers);
            setCompleted(true);
          } else {
            setStep((s) => s + 1);
          }
        }}>
        {({ isSubmitting }) => (
          <Form>
            <Stepper alternativeLabel activeStep={step}>
              {childrenArray.map((child, index) => (
                <Step key={child.props.label} completed={step > index || completed}>
                  <StepLabel>{child.props.label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            {currentChild}

            <Grid container spacing={2}>
              <Grid item>
                {step > 0 ? (
                  <Button
                    disabled={isSubmitting}
                    variant="outlined"
                    color="secondary"
                    onClick={() => setStep((s) => s - 1)}>
                    Volver
                  </Button>
                ) : (
                  <Link to="/">
                    <Button variant="outlined" color="secondary">
                      Volver
                    </Button>
                  </Link>
                )}
              </Grid>
              <Grid item>
                <Button
                  startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  type="submit">
                  {isSubmitting ? "Guardando, espere!" : isLastStep() ? "Guardar" : "Continuar"}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <br />
    </>
  );
}
