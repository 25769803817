import { useContext } from "react";
import { Context } from "../context/DataContext";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import Chip from "@material-ui/core/Chip";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Container from "@material-ui/core/Container";
import { object, number } from "yup";
import { Form, RenderDropdown, RenderInput } from "@shared/components/Form";
// import CloneAllRouteConfigs from "../components/CloneAllRouteConfigs";
import RenderOwnerConfig from "@shared/components/RenderOwnerConfig";
// import { toast } from "react-toastify";
import { GrEdit } from "react-icons/gr";
import Loading from "@shared/components/Loading";
import { UseRoute, UseUserConfig, UseOwnerConfig } from "@shared/server/fetcher";
import { useSWRConfig } from "swr";

const RouteConfig = () => {
  const { mutate } = useSWRConfig();
  const { state, updateUserConfig, modifyRouteConfig, updateOwnerConfig } = useContext(Context);
  const { user, route: stateRoute } = state;

  const { data: route, isLoading: routeLoading } = UseRoute(stateRoute.creditor_id, user.user_id);
  const { data: userConfig, isLoading: userLoading, key: k1 } = UseUserConfig(user.user_id);
  const { data: ownerConfig, isLoading: routeLoadingConfig, key: k2 } = UseOwnerConfig(route.user_id);

  if (userLoading || routeLoading || routeLoadingConfig) return <Loading />;

  const handlePrintAppChange = (event) => {
    mutate(k1, updateUserConfig(user.user_id, { printApp: event.target.value }), {
      optimisticData: { ...userConfig, printApp: event.target.value },
      populateCache: false,
    });
  };

  const handleOwnerConfigChange = (name, toggle) => {
    let result = [];
    result[name] = +toggle === 1 ? "0" : "1";

    mutate(k2, updateOwnerConfig(route.user_id, { ...result }), {
      optimisticData: { ...ownerConfig, ...result },
      populateCache: false,
    });
  };

  const initialValues = {
    creditor_id: route.creditor_id,
    mora: route.mora,
    mWeeks: route.mWeeks,
    approval: route.approval,
    rPerc: route.rPerc,
    rWeeks: route.rWeeks,
    renWeeks: route.renWeeks,
  };

  const validation = object({
    rPerc: number()
      .required("Porciento es requerido!")
      .min(0, "Porciento es muy bajito!")
      .max(60, "Porciento es muy alto!"),
    rWeeks: number().required("Cuotas es requerida!").min(0, "Cuotas es muy corta!").max(50, "Cuotas es muy alta!"),
  });

  const button = {
    icon: <GrEdit size="0.9em" />,
    label: "Guardar Configuracion",
    variant: "contained",
    color: "primary",
  };

  const submithandler = async (routeData) => {
    await modifyRouteConfig(routeData);
    mutate(`/route/info/${route.creditor_id}/${user.user_id}`);
  };

  return (
    <div className="container mt-3 mb-5">
      <Container className="mt-3" maxWidth="md" disableGutters>
        <Typography variant="h5" component="h3">
          Configuraciones
        </Typography>

        <Divider className="mt-1 mb-1" />

        <div style={{ display: "inline-flex" }}>
          <span className="pr-1">
            <b className="p-2">
              <Chip label={`W${user.user_id}`} />
            </b>
          </span>
          <Typography variant="subtitle1" component="p">
            {user.firstname} {user.lastname}
          </Typography>
        </div>

        <Card>
          <CardContent>
            <FormControl component="fieldset">
              <FormLabel component="legend">Que aplicacion de imprimir usar?</FormLabel>
              <RadioGroup
                row
                aria-label="gender"
                name="printApp"
                value={userConfig.printApp}
                onChange={handlePrintAppChange}>
                <FormControlLabel
                  value="RawBt"
                  control={<Radio />}
                  label={
                    <span>
                      <img alt="" src="/../../rawbt_24x24.png" /> RawBt
                    </span>
                  }
                />
                <FormControlLabel
                  value="QuickPrinter"
                  control={<Radio />}
                  label={
                    <span>
                      <img alt="" src="/../../quickprinter_24x24.png" /> Quick Printer
                    </span>
                  }
                />
              </RadioGroup>
            </FormControl>
          </CardContent>
        </Card>

        <Divider className="m-3" />

        <RenderOwnerConfig route={route} ownerConfig={ownerConfig} handleOwnerConfigChange={handleOwnerConfigChange} />
      </Container>

      <Container className="mt-3" maxWidth="md" disableGutters>
        <Typography variant="h6" component="p">
          Otras configuraciones
        </Typography>

        <div id="route-panel" className="mb-3" style={{ display: "inline-flex" }}>
          <span className="pr-1">
            <b>
              <Chip label={`R${route.creditor_id}`} />
            </b>
          </span>
          <Typography variant="subtitle1" component="p">
            {route.route_name} ({route.location})
          </Typography>
        </div>

        <Form initialValues={initialValues} validation={validation} submithandler={submithandler} button={button}>
          <Grid container>
            <Grid item xs={6}>
              <Box paddingBottom={2}>{RenderInput("rPerc", "Porciento", "number")}</Box>
            </Grid>
            <Grid item xs={6}>
              <Box paddingBottom={2}>{RenderInput("rWeeks", "Cuotas", "number")}</Box>
            </Grid>
          </Grid>

          <Box paddingBottom={2}>
            {RenderDropdown("mora", "Aplicar porciento de Mora", [
              { label: "0% - No aplicar mora", value: "0.00" },
              { label: "Aplicar 2.50%", value: "2.50" },
              { label: "Aplicar 3.00%", value: "3.00" },
              { label: "Aplicar 4.00%", value: "4.00" },
              { label: "Aplicar 5.00%", value: "5.00" },
              { label: "Aplicar 6.00%", value: "6.00" },
              { label: "Aplicar 7.00%", value: "7.00" },
              { label: "Aplicar 8.00%", value: "8.00" },
              { label: "Aplicar 9.00%", value: "9.00" },
              { label: "Aplicar 10.00%", value: "10.00" },
            ])}
          </Box>

          <Box paddingBottom={2}>
            {RenderDropdown("mWeeks", "Aplicar mora semana en Atrasos", [
              { label: "Despues de 1 semanas", value: "1" },
              { label: "Despues de 2 semanas", value: "2" },
              { label: "Despues de 3 semanas", value: "3" },
              { label: "Despues de 4 semanas", value: "4" },
              { label: "Despues de 5 semanas", value: "5" },
              { label: "Despues de 6 semanas", value: "6" },
              { label: "Despues de 7 semanas", value: "7" },
            ])}
          </Box>

          {/* <Box paddingBottom={3}>
            {RenderDropdown("approval", "Requerir aprobar nuevo prestamos", [
              { label: "No eh requerido", value: "0" },
              { label: "Despues de $4,000", value: "4000" },
              { label: "Despues de $6,000", value: "6000" },
              { label: "Despues de $8,000", value: "8000" },
              { label: "Despues de $10,000", value: "10000" },
              { label: "Despues de $12,000", value: "12000" },
              { label: "Despues de $20,000", value: "15000" },
              { label: "Despues de $20,000", value: "20000" },
              { label: "Despues de $25,000", value: "25000" },
            ])}
          </Box> */}

          <Box paddingBottom={3}>
            {RenderDropdown("renWeeks", "Permitir renovar prestamos", [
              { label: "En cualquier cuota", value: "0" },
              { label: "Restando 1 cuota", value: "1" },
              { label: "Restando 2 cuotass", value: "2" },
              { label: "Restando 3 cuotass", value: "3" },
              { label: "Restando 4 cuotass", value: "4" },
              { label: "Restando 5 cuotass", value: "5" },
              { label: "Restando 6 cuotass", value: "6" },
              { label: "No permitir Renovos", value: "-1" },
            ])}
          </Box>
          <Typography variant="body1" className="text-warning" component="p" paragraph>
            Estas configuraciones aplican unicamente a esta ruta.
          </Typography>
        </Form>

        {/* <Box component={"div"} paddingTop={3}>
          <CloneAllRouteConfigs
            cloneRouteConfigs={() =>
              cloneRouteConfigs({ creditor_id: routeConfig.creditor_id, user_id: userConfig.user_id })
            }
          />
        </Box> */}
      </Container>
    </div>
  );
};

export default RouteConfig;
