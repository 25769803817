import { useContext } from "react";
import { string, object } from "yup";
import { Form, RenderInput } from "@shared/components/Form";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Context } from "../context/DataContext";

const UserLookup = ({ history }) => {
  const { passwordResetLookup } = useContext(Context);

  const initialValues = {
    name: "",
    phone: "",
    cedula: "",
  };

  const validation = object({
    name: string().required("Nombre es requerido!"),
    cedula: string().required("Numero de Cedula es requerido!"),
  });

  const validation2 = object({
    name: string().required("Nombre es requerido!"),
    phone: string().required("Numero de telefono es requerido!"),
  });

  const button = { label: "Continuar", variant: "contained", color: "primary" };

  const submithandler = (data) => passwordResetLookup(data, () => history.replace("/password/resetmethod"));

  return (
    <div className="container mt-5 mb-5">
      <Typography variant="h5" component="h3" gutterBottom>
        Restablecer Contraña!
      </Typography>

      <Typography variant="subtitle1" component="p" paragraph>
        Ingrese las siguiente informacion y le ayudare a establecer una nueva contraña.
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader title="1. Busqueda de Cedula" />
            <CardContent>
              <Form initialValues={initialValues} validation={validation} submithandler={submithandler} button={button}>
                <Box paddingBottom={3}>{RenderInput("name", "Nombre Completo")}</Box>
                <Box paddingBottom={5}>{RenderInput("cedula", "Numero de Cedula", "number")}</Box>
              </Form>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Card>
            <CardHeader title="2. Busqueda de Numero" />
            <CardContent>
              <Form
                initialValues={initialValues}
                validation={validation2}
                submithandler={submithandler}
                button={button}>
                <Box paddingBottom={3}>{RenderInput("name", "Nombre Completo")}</Box>
                <Box paddingBottom={5}>{RenderInput("phone", "Numero de Telefono", "tel")}</Box>
              </Form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default UserLookup;
