import { useContext } from "react";
import { Context } from "../context/DataContext";
import { useParams } from "react-router";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { string, object } from "yup";
import { Form, RenderInput } from "@shared/components/Form";
import { UseUser } from "@shared/server/fetcher";
import Loading from "@shared/components/Loading";
import RenderBackButton from "@shared/components/RenderBackButton";

const UserUsername = ({ history }) => {
  const { id: user_id, t: type } = useParams();
  const { updateUsername } = useContext(Context);

  const { data: user, isLoading, mutate } = UseUser(user_id);

  if (isLoading) return <Loading />;

  const initialUsernameValues = {
    user_id: user_id,
    username: "",
    currentPassword: type === "employee" ? "tempPassword" : "",
    type,
  };

  const validationUsername = object({
    currentPassword: string().required("Ingrese su contraseña actual!"),
    username: string()
      .required("Usuario es requerido!")
      .min(4, "Su usuario debe ser minimos 4 letras/ numero!")
      .max(15, "Su usuario debe ser maximo 15 letras / numeros!"),
  });

  const buttonUsername = {
    label: "Actualizar Usuario",
    variant: "contained",
    color: "primary",
  };

  const submithandlerUsername = async (data) => {
    const redirect = type === "employee" ? `/employee/${user_id}` : "/profile";
    await mutate(
      updateUsername(data, () => history.replace(redirect)),
      {
        optimisticData: { ...user, username: data.username },
        populateCache: false,
      }
    );
  };

  return (
    <div className="mt-5">
      <Card raised>
        <CardHeader title="Usuario" />
        <CardContent>
          <Typography variant="body1" paragraph>
            En esta pagina puede cambiar el usuario!
          </Typography>
          <div>
            <Typography variant="h6" component="h4" gutterBottom>
              Usuario Actual: <i>{user.username}</i>
            </Typography>
            <Form
              initialValues={initialUsernameValues}
              validation={validationUsername}
              submithandler={submithandlerUsername}
              button={buttonUsername}>
              <Box paddingBottom={3}>{RenderInput("username", "Nuevo Usuario")}</Box>
              {type !== "employee" && (
                <Box paddingBottom={4}>{RenderInput("currentPassword", "Contraseña Actual", "password")}</Box>
              )}
            </Form>
            <div className="mt-4">
              <RenderBackButton />
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default UserUsername;
