import React from "react";
import logger from "./logService";
import ReactDOM from "react-dom";
import App from "./App";
import FullLoadingScreen from "@shared/components/FullLoadingScreen";
import CacheBuster from "./cacheBuster";
import { BrowserRouter } from "react-router-dom";
import register from "./registerServiceWorker";
// import reportWebVitals from "./reportWebVitals";
import { Provider } from "./context/DataContext";
import "bootstrap/dist/css/bootstrap.css";

if (import.meta.env.VITE_ENV === "beta") {
  logger.init();
  logger.identifyUser();
}

ReactDOM.render(
  <CacheBuster>
    {({ loading, isLatestVersion, refreshCacheAndReload }) => {
      if (loading) return <FullLoadingScreen />;
      if (!loading && !isLatestVersion && import.meta.env.VITE_ENV !== "local") {
        // refreshCacheAndReload();
      }
      return (
        <Provider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      );
    }}
  </CacheBuster>,
  document.getElementById("root")
);

//Temporarily disabled sw registration
register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
