import { useContext } from "react";
import { Context } from "../context/DataContext";
import DashboardDR from "./DashboardDR";
// import DashboardUS from "./DashboardUS";
import Loading from "@shared/components/Loading";
import { UseLoansXcobrar } from "@shared/server/fetcher";
import NoInternetContent from "@shared/components/NoInternetContent";

const Dashboard = () => {
  const { state } = useContext(Context);
  const { user, route: stateRoute } = state;

  const { data: loans, isLoading } = UseLoansXcobrar(stateRoute.creditor_id, user.user_id);

  if (isLoading) return <Loading />;
  if (!loans) return <NoInternetContent />;

  // if (!lastFetch.debtors || !lastFetch.loans) return <FullLoadingScreen />;
  // return route.route_location === "2" ? <DashboardUS cuadre={cuadre} /> : <Dashboard1 loans={loans} />;
  return <DashboardDR loans={loans} />;
};

export default Dashboard;
