import Box from "@nodes/@material-ui/core/Box";
import Button from "@nodes/@material-ui/core/Button";
import Typography from "@nodes/@material-ui/core/Typography";
import Grid from "@nodes/@material-ui/core/Grid";
import { printAbsAmount, printDate, newDate } from "../functions";
import Chip from "@nodes/@material-ui/core/Chip";
import Alert from "@nodes/@material-ui/lab/Alert";
// import PrintIcon from "@nodes/@material-ui/icons/Print";
import DeleteForeverIcon from "@nodes/@material-ui/icons/DeleteForever";
import { RiArrowGoBackLine } from "@nodes/react-icons/ri";
import { parseISO } from "@nodes/date-fns";
import { mainApp } from "@/config";

const RenderPaymentDetail = ({ history, payment = {} }) => {
  // const handlePaymentPrint = (data) => {};
  return (
    <div>
      {payment.is_active === "0" && (
        <Alert variant="outlined" className="mb-3" severity="error">
          <Typography variant="h6" component="h5" gutterBottom>
            <b>Pago Borrado!</b>
          </Typography>

          <Typography variant="body1" component="p">
            Este pago esta marcado como borrado!
          </Typography>
        </Alert>
      )}

      <Grid container>
        <Grid item xs={10}>
          <Typography variant="h6" component="h4">
            <img
              alt=""
              src={payment.isMain === "0" ? "/../../cobro-icon-57x57.png" : "/../../main-icon-57x57.png"}
              style={{ width: 22, height: 22, marginRight: 3 }}
            />
            Detalle del Pago
            <b className="p-2">
              <Chip label={`${payment.payment_id}`} />
            </b>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {/* <PrintIcon onClick={() => handlePaymentPrint(0)} className="text-info" /> */}
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Realizado en
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">
                {printDate(parseISO(payment.created_time), "dd-MMM-yy hh:mm:ss aaa")}
              </span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Cantidad
            </Typography>
            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{printAbsAmount(payment.paymentAmount)}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Realizado por
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{payment.created_user}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Fecha
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{printDate(newDate(payment.payment_date))}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Balance
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{printAbsAmount(payment.balance)}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Mora
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{printAbsAmount(payment.paymentMora)}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Atrasos
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{printAbsAmount(payment.atrasos > 0 ? payment.atrasos : 0)}</span>
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box component="div" p={1}>
            <Typography variant="body2" component="p">
              Adelantos
            </Typography>

            <Typography variant="h6" component="h4">
              <span className="badge badge-dark p-2">{printAbsAmount(payment.atrasos < 0 ? payment.atrasos : 0)}</span>
            </Typography>
          </Box>
        </Grid>

        {payment.is_active === "0" && (
          <>
            <Grid item xs={6}>
              <Box component="div" p={1}>
                <Typography variant="body2" component="p">
                  Borrado Por
                </Typography>

                <Typography variant="h6" component="h4">
                  <span className="badge badge-dark p-2">{payment.deletedUser}</span>
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box component="div" p={1}>
                <Typography variant="body2" component="p">
                  Borrado en
                </Typography>

                <Typography variant="h6" component="h4">
                  <span className="badge badge-dark p-2">
                    {printDate(new Date(1000 * payment.deletedTime), "dd-MMM-yy hh:mm:ss aaa")}
                  </span>
                </Typography>
              </Box>
            </Grid>
          </>
        )}
      </Grid>

      {mainApp === 1 && payment.is_active === "1" && (
        <div className="container m-1 mt-4">
          <Button
            fullWidth
            onClick={() =>
              history.push(`/delete/payment/${payment.payment_id}/${payment.money_id}/${payment.debtor_id}`)
            }
            startIcon={<DeleteForeverIcon />}
            color="secondary"
            variant="outlined">
            Borrar Pago!
          </Button>
        </div>
      )}

      <div className="container m-1 mt-3">
        <Button
          fullWidth
          startIcon={<RiArrowGoBackLine />}
          onClick={() => history.push(`/loan/payments/${payment.money_id}/${payment.debtor_id}`)}
          variant="outlined">
          Volver Atr&aacute;s
        </Button>
      </div>
    </div>
  );
};

export default RenderPaymentDetail;
