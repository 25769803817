import { useContext } from "react";
import { useParams } from "react-router";
import RenderDebtorForm from "@shared/components/RenderDebtorForm";
import { Context } from "../context/DataContext";
import Loading from "@shared/components/Loading";
import { UseRoute, UseOwnerConfig, UseDebtors, UseCustomerBalance, UseBankInformationv2 } from "@shared/server/fetcher";
import RenderDisabledCustomer from "@shared/components/RenderDisabledCustomer";
// import { useSWRConfig } from "swr";

const DebtorCreate = ({ history }) => {
  const { isAtraso } = useParams();
  // const { mutate } = useSWRConfig();
  const { state, createDebtor, createDebtorUnique } = useContext(Context);
  const { route, user, misc } = state;

  const { data: routeN, isLoading: routeLoading } = UseRoute(route.creditor_id, user.user_id);
  const { data: customer } = UseCustomerBalance(routeN?.user_id);
  const { data: bankInformation } = UseBankInformationv2(routeN?.user_id);
  const { data: routeConfig, isLoading: routeLoadingConfig } = UseOwnerConfig(route?.user_id);
  const { mutate } = UseDebtors(route.creditor_id, user.user_id);

  if (routeLoading || routeLoadingConfig) return <Loading />;

  const initialValues = {
    user_id: user.user_id,
    owner_id: routeN.user_id,
    creditor_id: route.creditor_id,
    name: "",
    cedula: "",
    phone: "",
    phone2: "",
    nickname: "",
    display_name: "",
    birthdate: "",
    address: "",
    work_address: "",
    next_to: "",
    cosigner: "",
    note: "",
    occupation: "",
    npayments: "",
    percentage: "",
    amount: "",
    actanotarial: "",
    zone_id: misc.id,
    uploadImage: false,
  };

  const button = { label: "Registrar Cliente", variant: "contained", color: "primary" };

  if (customer?.isDisabled && bankInformation?.BANK_OWNER_NAME)
    return <RenderDisabledCustomer bankInformation={bankInformation} />;

  const submithandler = async (data) => {
    if (routeConfig.allowDuplicateDebtor === false) {
      await createDebtorUnique(data, history.replace, isAtraso);
    } else {
      await createDebtor(data, history.replace, isAtraso);
    }
    await mutate();
  };

  return (
    <div className="container mb-5 mt-3">
      <RenderDebtorForm
        title="Registrar nuevo Cliente!"
        initialValues={initialValues}
        submithandler={submithandler}
        button={button}
        uploadImage
        cedula={false}
      />
    </div>
  );
};

export default DebtorCreate;
