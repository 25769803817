import { useContext } from "react";
import { Context } from "../context/DataContext";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography ";
import { Redirect } from "react-router-dom";
// import { useLocation } from "react-router";
import RenderLoginForm from "@shared/components/RenderLoginForm";
// import RenderClipboardCopy from "@shared/components/RenderClipboardCopy";

const Login = ({ history }) => {
  const { state, signinUser } = useContext(Context);
  const { user } = state;
  // const location = useLocation();

  if (user.user_id !== 0) return <Redirect to="/dashboard" />;

  // const destination = location.state ? location.state.from : "/dashboard";
  const submithandler = (data) => signinUser(data, history.replace);

  return (
    <Container fixed maxWidth="sm">
      <RenderLoginForm submithandler={submithandler} />

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <Typography variant="h6">Otros enlances!</Typography>

      <Box paddingBottom={2}>
        <a target="_self" href="https://wprestamos-cobro-alpha.web.app">
          (1) Enlance para cobro Alpha
        </a>
      </Box>

      <Box paddingBottom={2}>
        <a target="_self" href="https://wprestamos-cobro-betav2.web.app">
          (2) Enlance para cobro Beta{" "}
        </a>
      </Box>

      <Box paddingBottom={2}>
        <a target="_self" href="https://wprestamos-cobro-stagingv2.web.app">
          (3) Enlance para cobro Staging
        </a>
      </Box>

      <Box paddingBottom={5}>
        <a target="_self" href="https://prod-redux-persist.web.app">
          (4) Enlance para cobro Publico
        </a>
      </Box>
    </Container>
  );
};

export default Login;
