import React from "react";
import { MdPersonAdd, MdPersonAddAlt } from "react-icons/md";
import { BsPersonFill } from "react-icons/bs";
import { Fab, Action } from "react-tiny-fab";
import "react-tiny-fab/dist/styles.css";

const FabDebtorCreate = ({ history }) => {
  return (
    <Fab
      icon={<BsPersonFill size="1.5em" />}
      mainButtonStyles={{ backgroundColor: "#23569e" }}
      alwaysShowTitle={true}
      style={{ bottom: 0, right: 0 }}>
      <Action style={{}} onClick={() => history.push("/debtor/create/withAtrasos")} text="Crear Cliente Antiguo">
        <MdPersonAddAlt size="1.5em" />
      </Action>

      <Action
        style={{ backgroundColor: "#23569e" }}
        onClick={() => history.push("/debtor/create")}
        text="Crear Cliente Nuevo">
        <MdPersonAdd size="1.5em" />
      </Action>
    </Fab>
  );
};

export default FabDebtorCreate;
