import { useContext } from "react";
import { Redirect } from "react-router-dom";
import { Context } from "../context/DataContext";
import PhoneVerify from "@shared/components/PhoneVerify";

const UserPhoneVerify = ({ history }) => {
  const { state, createVerifyPhone, phoneVerification } = useContext(Context);

  if (state.user.user_id === 0) return <Redirect to="/" />;
  const submithandler = (data) => phoneVerification(data, () => history.replace("/"));

  return (
    <div className="container mt-4 mb-5">
      <PhoneVerify
        submithandler={submithandler}
        createVerifyPhone={createVerifyPhone}
        userData={state.user}
        updateUser
      />
    </div>
  );
};

export default UserPhoneVerify;
