import { useContext, useState, useEffect } from "react";
import { Context } from "../context/DataContext";
import { useParams } from "react-router";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
// import Divider from "@material-ui/core/Divider";
import DebtorNameHeader from "@shared/components/DebtorNameHeader";
import DebtorMenu from "@shared/components/DebtorMenu";
import Loading from "@shared/components/Loading";
import { UseOwnerRoutes, UseDebtor } from "@shared/server/fetcher";
import { object } from "yup";
import { Form, RenderDropdown } from "@shared/components/Form";
import { toast } from "react-toastify";
// import { UseUser } from "@shared/server/fetcher";
import Typography from "@material-ui/core/Typography";
import RenderBackButton from "@shared/components/RenderBackButton";
import { useSWRConfig } from "swr";

const MoveDebtor = ({ history }) => {
  const { mutate } = useSWRConfig();
  const { id: debtor_id } = useParams();
  const { state, moveDebtorRoute } = useContext(Context);
  const { route, user } = state;
  const [selectRoutes, setSelectRoutes] = useState([]);

  const { data: linkedRoutes } = UseOwnerRoutes(route.user_id, 987854);
  const { data: debtor } = UseDebtor(debtor_id, user.user_id);

  useEffect(() => {
    if (!linkedRoutes || !debtor) return;
    const filteredRoutes = linkedRoutes.filter((x) => x.creditor_id !== debtor.creditor_id);
    let customRoutes = [];

    for (let i = 0; i < filteredRoutes.length; i++) {
      const { route_name, location, creditor_id } = filteredRoutes[i];
      customRoutes.push({ label: `${route_name} (${location})`, value: creditor_id });
    }
    setSelectRoutes(customRoutes);
  }, [linkedRoutes, debtor]);

  if (!linkedRoutes || !debtor) return <Loading />;

  const initialValues = {
    creditor_id: debtor.creditor_id,
    debtor_id: debtor.debtor_id,
    moveDebtorRoute: "",
  };

  const validation = object({});

  const button = { label: "Mover Cliente", variant: "contained", color: "primary" };

  const submithandler = (routeData) => handleRouteCreate(routeData);

  const handleRouteCreate = async (data) => {
    if (+data.creditor_id === +data.moveDebtorRoute) {
      //Just in case but this error will never happend because of filteredRoutes.
      toast.error("Haz selecionado la misma ruta!");
      return;
    }

    const res = await moveDebtorRoute(data);
    if (res) {
      await mutate(`/debtors/${route.creditor_id}/${user.user_id}`);
      await mutate(`/debtors/${data.moveDebtorRoute}/${user.user_id}`);
      history.replace("/debtors");

      toast.success("Cliente fue movido de ruta!");
    }
  };

  return (
    <div className="mt-2 mb-5">
      <Container maxWidth="md">
        <DebtorNameHeader debtor={debtor}>
          <DebtorMenu history={history} debtor={debtor} profile />
        </DebtorNameHeader>

        <Typography variant="h6" gutterBottom>
          Mover Cliente de Ruta!
        </Typography>

        <Typography variant="h6" paragraph>
          Selecione la ruta que decea mover este cliente.
        </Typography>

        {/* <Divider /> */}

        <Form initialValues={initialValues} validation={validation} submithandler={submithandler} button={button}>
          <Box paddingBottom={4} paddingTop={2}>
            {RenderDropdown("moveDebtorRoute", "Ruta selecionada", [
              { label: "Por favor selecione una ruta", value: "" },
              ...selectRoutes,
            ])}
          </Box>
        </Form>

        <div className="mt-4">
          <RenderBackButton />
        </div>
      </Container>
    </div>
  );
};

export default MoveDebtor;
