import { useContext } from "react";
import { useParams } from "react-router";
import { Context } from "../context/DataContext";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
// import SubmitButton from "@shared/components/SubmitButton";
// import CancelIcon from "@material-ui/icons/Cancel";
import DebtorNameHeader from "@shared/components/DebtorNameHeader";
import Loading from "@shared/components/Loading";
import { UseDebtor, UseDebtorPaidLoans, UseDebtorLoans } from "@shared/server/fetcher";
import { useSWRConfig } from "swr";
import { object } from "yup";
import { Form, RenderInput } from "@shared/components/Form";
import RenderBackButton from "@shared/components/RenderBackButton";

const DebtorDelete = ({ history }) => {
  const words = "eliminar cliente";
  const { mutate } = useSWRConfig();
  const { state, debtorDelete } = useContext(Context);
  const { id: debtor_id } = useParams();
  const { user, route: r2 } = state;

  const { data: debtor, isLoading: isLoadingDebtor } = UseDebtor(debtor_id, user.user_id);
  const { data: loans, isLoading: isLoadingLoans } = UseDebtorPaidLoans(debtor_id, user.user_id);

  const { data: loans2, isLoading: isLoadingLoans2 } = UseDebtorLoans(debtor_id, r2.creditor_id, user.user_id);

  if (isLoadingDebtor || isLoadingLoans || isLoadingLoans2) return <Loading />;

  const unique = [...new Set([...loans, ...loans2].map((item) => item.money_id))];

  const initialValues = {
    confirm_text: "",
  };

  const validation = object({});

  const button = { label: "Eliminar Cliente", variant: "contained", color: "primary" };

  const submithandler = async ({ confirm_text }) => {
    if (confirm_text.toLowerCase().trim() !== words) return;

    await debtorDelete(debtor.debtor_id, user.user_id, history);

    mutate(`/debtors/${route.creditor_id}/${user.user_id}`);
    mutate(`/loans/${route.creditor_id}/${user.user_id}`);
    mutate(`/xcobrar/loans/${route.creditor_id}/${user.user_id}`);
  };

  return (
    <div className="container mt-5">
      <DebtorNameHeader debtor={debtor} history={history} />
      <Card>
        <CardContent>
          <Typography variant="h5" component="h3" className="text-danger">
            Confirmaci&oacute;n
          </Typography>

          <Typography variant="h6" component="h4" gutterBottom>
            Decea borrar este Cliente?
          </Typography>

          <Typography variant="body1" className="text-danger" component="h4" gutterBottom>
            <b>Hay borrar este cliente, estaria borrando este cliente y {unique?.length} prestamos!</b>
          </Typography>

          <Typography variant="body1" component="h4" gutterBottom>
            Si esta seguro de querer eliminar este cliente e incluyendo {unique?.length} prestamos, escriba hacia abajo{" "}
            <b>{words}</b>.
          </Typography>

          <Form initialValues={initialValues} validation={validation} submithandler={submithandler} button={button}>
            <Box paddingBottom={3}>{RenderInput("confirm_text", `Escriba ${words}`, "text")}</Box>
          </Form>

          <div className="mt-4">
            <RenderBackButton />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default DebtorDelete;
