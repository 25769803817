import { useRef, PureComponent } from "react";
import { useParams } from "react-router";
import { FcPrint } from "react-icons/fc";
import { AiOutlineFilePdf } from "react-icons/ai";
import Loading from "@shared/components/Loading";
import { UseCustomerInvoice } from "@shared/server/fetcher";
import RenderInvoice from "@shared/components/RenderInvoice";
import { useReactToPrint } from "react-to-print";
import "@shared/pages/styles/invoice.css";

export class ComponentToPrint extends PureComponent {
  render() {
    return (
      <div>
        <RenderInvoice data={this.props.data} />
      </div>
    );
  }
}

const Invoice = () => {
  const { id: invoice_id, id2: user_id } = useParams();

  const pageStyle = `
  @page {
    size: 80mm 50mm;
  }
  @media all {
    .pagebreak {
      display: none;
    }
  }
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
  `;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle,
  });

  const { data, isLoading } = UseCustomerInvoice(user_id, invoice_id);

  if (isLoading) return <Loading />;

  return (
    <div className="page-content container mt-3 mb-5">
      <div className="page-header text-blue-d2">
        <div className="page-tools">
          <div className="action-buttons">
            <button className="btn bg-white btn-light mx-1px text-95" onClick={handlePrint}>
              <FcPrint size="1.5em" />
              Imprimir
            </button>
            <button className="btn disabled bg-white btn-light mx-1px text-95">
              <AiOutlineFilePdf size="1.4em" />
              <s>Exportar</s>
            </button>
          </div>
        </div>
      </div>

      <ComponentToPrint ref={componentRef} data={data} />
    </div>
  );
};

export default Invoice;
