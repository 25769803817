import { useContext } from "react";
import { useParams } from "react-router";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import { Context } from "../context/DataContext";
import DebtorNameHeader from "@shared/components/DebtorNameHeader";
import DisplayLoanStatus from "@shared/components/DisplayLoanStatus";
// import RenderPaymentForm from "@shared/components/RenderPaymentForm";
import RenderPaymentDepositForm from "@shared/components/RenderPaymentDepositForm";
import RenderPaymentInfo from "@shared/components/RenderPaymentInfo";
import DisplayLastPaymentAlert from "@shared/components/DisplayLastPaymentAlert";
import RenderDisabledCustomer from "@shared/components/RenderDisabledCustomer";
import LoanDetailMenu from "@shared/components/LoanDetailMenu";
import Loading from "@shared/components/Loading";
import { UseLoan, UseRoute, UseOwnerConfig, UseCustomerBalance, UseBankInformationv2 } from "@shared/server/fetcher";
import { useSWRConfig } from "swr";
import { useHistory } from "react-router-dom";
import RenderUserRestriction from "../components/RenderUserRestriction";

const PaymentCreate = () => {
  const history = useHistory();
  const { mutate: globalMutate } = useSWRConfig();
  const { state, createPayment } = useContext(Context);
  const { id: money_id, id2: debtor_id } = useParams();
  const { route, user, restritec_users } = state;

  const { data: currentLoan, isLoading, mutate } = UseLoan(money_id, debtor_id, route.creditor_id, user.user_id);
  const { data: routeN, isLoading: routeLoading } = UseRoute(route.creditor_id, user.user_id);
  const { data: ownerConfig, isLoading: routeLoadingConfig } = UseOwnerConfig(route.user_id);
  const { data: customer } = UseCustomerBalance(routeN?.user_id);
  const { data: bankInformation } = UseBankInformationv2(routeN?.user_id);

  if (isLoading || routeLoading || routeLoadingConfig) return <Loading />;
  if (customer?.isDisabled && bankInformation?.BANK_OWNER_NAME)
    return <RenderDisabledCustomer bankInformation={bankInformation} />;

  const createPaymentHandlder = async (data) => {
    await createPayment({ ...data, isCobro: routeN.isCobro }, currentLoan, mutate);
    globalMutate(`/xcobrar/loans/${route.creditor_id}/${user.user_id}`);
    globalMutate(`/loan/payments/${money_id}/${debtor_id}`);
    history.replace("/");
  };

  return (
    <div className="mt-3 mb-5">
      <Container maxWidth="md" disableGutters>
        <DebtorNameHeader debtor={currentLoan}>
          <LoanDetailMenu history={history} loan={currentLoan} />
        </DebtorNameHeader>
        <DisplayLastPaymentAlert loan={currentLoan} />
        {/* {currentLoan.balance > 0 && ( */}
        <Box component="div" p={1}>
          <DisplayLoanStatus loan={currentLoan} />
        </Box>
        {/* )} */}
        <Box component="div" p={1}>
          <RenderPaymentInfo loan={currentLoan} />
        </Box>

        {restritec_users.includes(+user.user_id) ? (
          <RenderUserRestriction />
        ) : (
          <Box component="div" p={1}>
            <RenderPaymentDepositForm
              showPaymentDate
              config={{ ...ownerConfig, autoPrintPayment: "0", moraPercent: routeN.mora, moraWeeks: routeN.mWeeks }}
              loan={{
                ...currentLoan,
                user_id: user.user_id,
                collect_date: routeN.current_week_date,
                payment_date: route.today_date,
                creditor_id: route.creditor_id,
              }}
              createPayment={createPaymentHandlder}
            />
          </Box>
        )}
        {/* <Box component="div" p={1}>
          <RenderPaymentForm
            showPaymentDate
            config={{ ...ownerConfig, autoPrintPayment: "0", moraPercent: routeN.mora, moraWeeks: routeN.mWeeks }}
            loan={{
              ...currentLoan,
              user_id: user.user_id,
              collect_date: routeN.current_week_date,
              payment_date: route.today_date,
              creditor_id: route.creditor_id,
            }}
            createPayment={createPaymentHandlder}
          />
        </Box> */}
      </Container>
    </div>
  );
};

export default PaymentCreate;
