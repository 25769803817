import { useContext, useState, useEffect } from "react";
import { Context } from "../context/DataContext";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import DatePicker from "@shared/components/DatePicker";
import XcobrarLoanList from "@shared/components/XcobrarLoanList";
import { printAbsAmount, printAmount, sortArrayObjs2 } from "@shared/functions";
import { UseCobroList, UseRoute } from "@shared/server/fetcher";
import Loading from "@shared/components/Loading";
import Card from "../components/Card";
import { FcEmptyFilter, FcClearFilters } from "react-icons/fc";
import { AiOutlinePushpin } from "react-icons/ai";
import NoInternetContent from "@shared/components/NoInternetContent";
import RenderUserRestriction from "../components/RenderUserRestriction";

const CobroList = ({ history }) => {
  const { state } = useContext(Context);
  const { route: stateRoute, user, restritec_users } = state;

  const [currentDate, setCurrentDate] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("default");
  const [filterDescription, setFilterDescription] = useState(null);
  const [filteredLoans, setFilteredLoans] = useState([]);

  const { data: route, isLoading: routeLoading } = UseRoute(stateRoute.creditor_id, user.user_id);

  const d22 =
    route?.collect_week_date === route?.today_date && route?.isCobro === "1"
      ? route?.collect_week_date
      : route?.next_week_date;
  const { data: cobroList, isLoading } = UseCobroList(route?.creditor_id, currentDate ?? d22);

  useEffect(() => {
    if (route) {
      setCurrentDate(d22);
    }
    //eslint-disable-next-line
  }, [route]);

  useEffect(() => {
    if (!cobroList) return;
    let loansResult;
    if (selectedFilter === "elapsed") {
      loansResult = sortArrayObjs2(cobroList.loans, "elapsed", "money_id");
      setFilterDescription("Prestamos ordenado de la semana 1 a #!");
    } else if (selectedFilter === "default") {
      loansResult = cobroList.loans;
      setFilterDescription("Prestamos ordenado en orden de Caida!");
    }

    setFilteredLoans(loansResult);
  }, [cobroList, selectedFilter]);

  if (restritec_users.includes(+user.user_id)) return <RenderUserRestriction />;

  if (isLoading || routeLoading) return <Loading />;
  if (!cobroList) return <NoInternetContent />;

  const handleFilterLoans = (filter) => {
    handleClose();
    setSelectedFilter(filter);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const atrasosSum = cobroList.loans.reduce((acc, obj) => {
    return +obj.statusAmount > 0 ? acc + +obj.statusAmount : acc;
  }, 0);

  const adelantoSum = cobroList.loans.reduce((acc, obj) => {
    return +obj.statusAmount < 0 ? acc + +obj.statusAmount : acc;
  }, 0);

  // let revStatus = {
  //   status1: { amount: 0, label: "" },
  //   status2: { amount: 0, label: "" },
  //   status3: { amount: 0, label: "" },
  //   status4: { amount: 0, label: "" },
  // };

  // for (var i = 0; i < cobroList.loans.length; i++) {
  //   const { npayments, amount, elapsed } = cobroList.loans[i];
  //   if (elapsed === npayments) {
  //     revStatus.status1.amount += amount;
  //     revStatus.status1.label = elapsed;
  //   } else if (elapsed + 1 === npayments) {
  //     revStatus.status2.amount += amount;
  //     revStatus.status2.label = elapsed;
  //   } else if (elapsed + 2 === npayments) {
  //     revStatus.status3.amount += amount;
  //     revStatus.status3.label = elapsed;
  //   } else if (elapsed + 3 === npayments) {
  //     revStatus.status4.amount += amount;
  //     revStatus.status4.label = elapsed;
  //   }
  // }

  return (
    <div className="container mt-3 mb-5">
      <Typography variant="h6" component="h4">
        Estado de la ruta ({cobroList.count}): {printAmount(cobroList.amount)}
      </Typography>

      <Grid container>
        <Grid item xs={7}>
          <DatePicker setCurrentDate={setCurrentDate} date={currentDate} route={route} />
        </Grid>
        <Grid item xs={5}>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={() => history.push(`/xcobrar/changes/${currentDate}`)}>
            Ver Cambios
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Card style={{ background: "#ffc107", color: "#fff" }}>
            <Typography variant="body1">Atrasos {printAbsAmount(atrasosSum)}</Typography>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card style={{ background: "#17a2b8", color: "#fff" }}>
            <Typography variant="body1">Adelantos {printAbsAmount(adelantoSum)}</Typography>
          </Card>
        </Grid>
      </Grid>

      {/* <Card>
        <Grid container spacing={1}>
          <Grid item xs={6} sm={3}>
            <Typography variant="body1" component="p">
              <span className="badge badge-pill badge-dark pr-1">
                <span style={{ padding: 2, fontSize: 13 }}>{revStatus.status1.label}</span>
              </span>{" "}
              {printAmount(revStatus.status1.amount)}
            </Typography>
          </Grid>

          <Grid item xs={6} sm={3}>
            <Typography variant="body1" component="p">
              <span className="badge badge-pill badge-dark pr-1">
                <span style={{ padding: 2, fontSize: 13 }}>{revStatus.status2.label}</span>
              </span>{" "}
              {printAmount(revStatus.status2.amount)}
            </Typography>
          </Grid>

          <Grid item xs={6} sm={3}>
            <Typography variant="body1" component="p">
              <span className="badge badge-pill badge-dark pr-1">
                <span style={{ padding: 2, fontSize: 13 }}>{revStatus.status3.label}</span>
              </span>{" "}
              {printAmount(revStatus.status3.amount)}
            </Typography>
          </Grid>

          <Grid item xs={6} sm={3}>
            <Typography variant="body1" component="p">
              <span className="badge badge-pill badge-dark pr-1">
                <span style={{ padding: 2, fontSize: 13 }}>{revStatus.status4.label}</span>
              </span>{" "}
              {printAmount(revStatus.status4.amount)}
            </Typography>
          </Grid>
        </Grid>
      </Card> */}

      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        {selectedFilter === "default" ? <FcEmptyFilter size="1.8em" /> : <FcClearFilters size="1.8em" />}
      </Button>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => handleFilterLoans("default")}>
          {selectedFilter === "default" && <AiOutlinePushpin className="text-primary" size="1.3em" />}
          Ordenar en orden de caida!
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => handleFilterLoans("elapsed")}>
          {selectedFilter === "elapsed" && <AiOutlinePushpin className="text-primary" size="1.3em" />}
          Ordenar por semana Actual!
        </MenuItem>
      </Menu>

      <Typography variant="body2">{filterDescription}</Typography>

      {filteredLoans.map((loan) => (
        <XcobrarLoanList key={loan.money_id} loan={loan} />
      ))}
    </div>
  );
};

export default CobroList;
