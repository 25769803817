import { useContext } from "react";
import { useParams } from "react-router";
// import SubmitButton from "@shared/components/SubmitButton";
import Typography from "@material-ui/core/Typography";
// import Box from "@material-ui/core/Box";
import { Context } from "../context/DataContext";
// import Divider from "@material-ui/core/Divider";
// import SyncIcon from "@material-ui/icons/Sync";
// import { BiDownload } from "react-icons/bi";
// import RenderLoanContract from "@shared/components/RenderLoanContract";
// import { getDebtorSelector } from "../context/Selectors";
import DebtorNameHeader from "@shared/components/DebtorNameHeader";
// import { generateContract, printDate, newDate, printAmount } from "../@shared/functions";
import DebtorMenu from "@shared/components/DebtorMenu";
import Loading from "@shared/components/Loading";
import RenderDebtorImages from "@shared/components/RenderDebtorImages";
import { UseDebtor, UseDebtorImages } from "@shared/server/fetcher";

const DebtorImages = ({ history }) => {
  const { id: debtor_id, id2: dUniqueId } = useParams();
  const { state } = useContext(Context);
  const { route, user } = state;

  const { data: debtor, isLoading } = UseDebtor(debtor_id, user.user_id);
  const { data: images, isLoading: isLoadingImages } = UseDebtorImages(dUniqueId, route.creditor_id);

  if (isLoading) return <Loading />;

  const RenderImages = ({ images }) => {
    return (
      <>
        {images?.length > 0 ? (
          <RenderDebtorImages images={images} />
        ) : (
          <div className="container">
            <Typography variant="h5" gutterBottom>
              No Fotos disponible!
            </Typography>
            <Typography variant="body1">El sistema no tiene ninguna foto almacenada para este cliente!</Typography>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="mb-5">
      <DebtorNameHeader debtor={debtor}>
        <DebtorMenu history={history} debtor={debtor} />
      </DebtorNameHeader>

      {isLoadingImages ? <Loading /> : <RenderImages images={images} />}
    </div>
  );
};

export default DebtorImages;
