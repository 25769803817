import { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { Context } from "../context/DataContext";

const ProtectedRoute = ({ component: Component, render, ...rest }) => {
  const { getJwtToken } = useContext(Context);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (getJwtToken() !== null) {
          return Component ? <Component {...props} /> : render(props);
        } else {
          return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
        }
      }}
    />
  );
};

export default ProtectedRoute;
