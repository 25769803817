import React, { useState } from "react";
import Modal from "@nodes/react-bootstrap/Modal";
import Box from "@nodes/@material-ui/core/Box";
import Button from "@nodes/@material-ui/core/Button";
import Typography from "@nodes/@material-ui/core/Typography";
// import FloatingButton from "./FloatingButton";
import { Form, RenderInput } from "./Form";
import { string, object } from "@nodes/yup";
// import AddIcon from "@nodes/@material-ui/icons/Add";

const CreateOldReferral = ({ handleCreateReferral, user_id }) => {
  const [showModal, setShowModal] = useState(false);
  const [time, setTime] = useState(null);

  const initialValues = {
    time,
    user_id,
    invited_by: user_id,
    phone: "",
    nickname: "",
    country: "DO",
  };

  const validation = object({
    nickname: string()
      .required("Nombre es requerida!")
      .min(3, "Nombre tiene que ser al menos 3 letras!")
      .max(25, "Nombre tiene que ser menos de 25 letras!"),
    phone: string().required("Telefono es requerido!").min(10, "Telefono es muy corto!"),
  });

  const button = { label: "Guardar", color: "primary", variant: "contained" };

  const submithandler = async (data) => {
    await handleCreateReferral(data);
    setTime(new Date());
  };

  return (
    <>
      <div className="text-center">
        <Button variant="outlined" className="text-info" onClick={() => setShowModal(!showModal.show)}>
          Agregar Personas invitada!
        </Button>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)} backdrop="static" keyboard={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <Typography variant="h5" className="text-success">
              Persona Invitada
            </Typography>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container mb-4">
            <Typography variant="body1" paragraph>
              Como se llama esa persona?
            </Typography>
            <Form initialValues={initialValues} validation={validation} submithandler={submithandler} button={button}>
              <Box paddingBottom={3}>{RenderInput("nickname", "Nombre", "text", true, "outlined")}</Box>
              <Box paddingBottom={3}>{RenderInput("phone", "Telefono", "number", false, "outlined")}</Box>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateOldReferral;
