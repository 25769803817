import { useContext } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Chip from "@material-ui/core/Chip";
import { string, object } from "yup";
import { Form, RenderInput } from "@shared/components/Form"; //RenderDropdown
import { Context } from "../context/DataContext";
// import { toast } from "react-toastify";
import { GrEdit } from "react-icons/gr";
import Loading from "@shared/components/Loading";
import { UseRoute } from "@shared/server/fetcher";
import { getSpanishDays, printDate, newDate } from "@shared/functions";

const RouteModify = () => {
  const { state, modifyRoute } = useContext(Context);
  const { user, route } = state;

  const { data, isLoading, mutate } = UseRoute(route.creditor_id, user.user_id);

  if (isLoading) return <Loading />;

  const initialValues = {
    creditor_id: data.creditor_id,
    route_name: data.route_name,
    location: data.location,
    phone: data.phone,
    phone2: data.phone2,
    collect_day: data.day,
  };

  const validation = object({
    route_name: string().required("Nombre de la ruta es requerido!").min(5, "Nombre de la ruta es muy corto!"),
    phone: string().required("Numero de telefono es requerido!").min(10, "Numero de telefono invalido!"),
    phone2: string().min(10, "Numero de telefono invalido!"),
    location: string().required("Nombre de la ubicacion es requerido!").min(4, "Nombre de la zona es muy corto!"),
  });

  const button = { icon: <GrEdit size="0.9em" />, label: "Modificar Ruta", variant: "contained", color: "primary" };

  const submithandler = async (routeData) => {
    await mutate(modifyRoute(routeData), {
      populateCache: false,
    });
  };

  return (
    <div className="container mt-4 mb-5">
      <Container maxWidth="md" disableGutters>
        <Typography variant="h5" component="h3" gutterBottom>
          <b className="pr-1">
            <Chip label={`R${route.creditor_id}`} />
          </b>
          Modificar Ruta!
        </Typography>

        <Form initialValues={initialValues} validation={validation} submithandler={submithandler} button={button}>
          <Box paddingBottom={2}>{RenderInput("route_name", "Nombre de la Ruta", "text")}</Box>
          <Box paddingBottom={2}>{RenderInput("location", "Zona o Ubicacion de la Ruta", "text")}</Box>
          <Grid container>
            <Grid item xs={6}>
              <Box paddingBottom={2}>{RenderInput("phone", "Numero de Telefono", "number")}</Box>
            </Grid>
            <Grid item xs={6}>
              <Box paddingBottom={2}>{RenderInput("phone2", "Numero de Telefono 2", "number")}</Box>
            </Grid>
          </Grid>

          {/* <Box paddingBottom={2}>
            {RenderDropdown("collect_day", "Dia de Cobro", [
              { label: "Lunes", value: 1 },
              { label: "Martes", value: 2 },
              { label: "Miercoles", value: 3 },
              { label: "Jueves", value: 4 },
              { label: "Viernes", value: 5 },
              { label: "Sabado", value: 6 },
              { label: "Domingo", value: 7 },
            ])}
          </Box> */}
        </Form>

        <Box component={"div"} paddingTop={2}>
          <ul className="list-group mb-3">
            <li className="list-group-item">
              <Typography variant="body1" component="p">
                Dia de Cobro: {getSpanishDays(data.collect_day)}
              </Typography>
            </li>

            <li className="list-group-item">
              <Typography variant="body1" component="p">
                Ruta Creada: {printDate(newDate(data.created_date))}
              </Typography>
            </li>
          </ul>
        </Box>

        <Box className="mt-5" component={"div"} p={1}>
          <Typography variant="h6" gutterBottom>
            Elminar esta Ruta!
          </Typography>
          <Typography variant="body1" paragraph>
            Completamente elimar y borrar toda el contendido de esta ruta, incluyendo todo los clientes, préstamos y
            pagos!
          </Typography>
        </Box>

        <Link className="text-danger" to={`/route/delete/${route.creditor_id}/${user.user_id}`}>
          Eliminar Ruta!
        </Link>
      </Container>
    </div>
  );
};

export default RouteModify;
