import { useState, useContext } from "react";
import { Context } from "../context/DataContext";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import RouteModify from "./RouteModify";
import RouteConfig from "./RouteConfig";
// import AccessCodes from "./AccessCodes";
import Aperturas from "./Aperturas";
import RenderUserRestriction from "../components/RenderUserRestriction";
// import AtrasosLetter from "./AtrasosLetter";
// import Contract from "./Contract";

const Settings = () => {
  const { state } = useContext(Context);
  const { user, restritec_users } = state;
  const [selectedTab, setSelectedTab] = useState(0);

  if (restritec_users.includes(+user.user_id)) return <RenderUserRestriction />;

  const handleTabChange = (event, newTab) => {
    setSelectedTab(newTab);
  };

  return (
    <>
      <Tabs
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        value={selectedTab}
        onChange={handleTabChange}>
        <Tab label="Ruta" />
        <Tab label="Configuaciones" />
        {/* <Tab label="Codigo Aceso" /> */}
        <Tab label="Apertura" />
        {/* <Tab label="Contrato" /> */}
        {/* <Tab label="Carta de Atraso" />
        <Tab label="Carta de Saldo" /> */}
      </Tabs>

      {selectedTab === 0 && <RouteModify />}
      {selectedTab === 1 && <RouteConfig />}
      {/* {selectedTab === 2 && <AccessCodes />} */}
      {selectedTab === 2 && <Aperturas />}
      {/* {selectedTab === 1 && <Contract />}
      {selectedTab === 2 && <AtrasosLetter />} */}
    </>
  );
};

export default Settings;
