import { useEffect } from "react";
import { useContext } from "react";
import { Context } from "../context/DataContext";
import { useParams } from "react-router";
import Loading from "@shared/components/Loading";
import { UseOwnerConfig } from "@shared/server/fetcher";
import { useHistory } from "@nodes/react-router-dom";
import { replaceNonDigits } from "@shared/functions";

const RedirectPage = () => {
  const history = useHistory();
  const { ced: cedula, dUn: dUniqueId, mId: money_id } = useParams();
  const { state } = useContext(Context);
  const { route } = state;

  const { data: ownerConfig } = UseOwnerConfig(route.user_id);

  useEffect(() => {
    if (!ownerConfig) return;
    if (ownerConfig.autoDepurar === "1" && cedula && replaceNonDigits(cedula) > 0) {
      if (+money_id > 0) {
        history.replace(`/debtor/loans/check/${dUniqueId}/${cedula}/${money_id}`);
      } else {
        history.replace(`/debtor/loans/check/${dUniqueId}/${cedula}`);
      }
    } else {
      if (+money_id > 0) {
        history.replace(`/loan/renewal/${dUniqueId}/${money_id}`);
      } else {
        if (dUniqueId === "newdebtor") {
          history.replace(`/debtor/create/${cedula}`);
        } else {
          history.replace(`/loan/create/${dUniqueId}`);
        }
      }
    }
  }, [ownerConfig]);

  return <Loading />;
};

export default RedirectPage;
