import { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import Container from "@material-ui/core/Container";
import SearchBox from "@shared/components/common/SearchBox";
import { CgUserList } from "react-icons/cg";
import FullLoadingScreen from "@shared/components/FullLoadingScreen";
import { RemoveAccents, sortArrayObjs, indentifySearchType } from "@shared/functions";
import { getXcobrarLoans } from "../context/Selectors";
import { FcEmptyFilter, FcClearFilters } from "react-icons/fc";
import { AiOutlinePushpin } from "react-icons/ai";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import XcobrarLoan from "@shared/components/XcobrarLoan";
// import Skeleton from "../components/SkeletonElement";
// import BottomNavigation from "../components/BottomNavigation";

const XCobrar = ({ loans = [] }) => {
  const [searchText, setSearchText] = useState("");
  // const [xCobrar, setXcobrar] = useState({ count: 0, amount: 0, list: [] });
  // const [loanFilter, setLoanFilter] = useState("xCobrar");
  const [filteredLoans, setFilteredLoans] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("xCobrar");
  const [filterDescription, setFilterDescription] = useState(null);

  useEffect(() => {
    let loansResult;
    if (selectedFilter === "xCobrar") {
      const xCobrarLoans = getXcobrarLoans(loans);
      // setXcobrar(xCobrarLoans);
      loansResult = xCobrarLoans.list;
      setFilterDescription("Prestamos x Cobrar y Vencidos recientemente!");
    } else if (selectedFilter === "Al Dia") {
      loansResult = loans.filter((loan) => loan.statusText === "Al Dia");
      setFilterDescription("Prestamos x Cobrar al Dia!");
    } else if (selectedFilter === "atrasos") {
      loansResult = loans.filter((loan) => loan.statusText === "Atrasos");
      setFilterDescription("Prestamos con pagos en Atrasos!");
    } else if (selectedFilter === "sale_hoy") {
      loansResult = loans.filter((loan) => loan.sale_hoy === true);
      setFilterDescription("Prestamos saliente de esta semana!");
    } else if (selectedFilter === "xRenewal") {
      const xRenewalLoans = loans.filter((loan) => loan.xRenewal === true);
      const sorted = sortArrayObjs(xRenewalLoans, "current_week", "completed");
      loansResult = sorted.reverse();
      setFilterDescription("Prestamos para renovar en sus ultima 3 semanas!");
    } else if (selectedFilter === "new_loan") {
      loansResult = loans.filter((loan) => loan.new_loan === true);
      setFilterDescription("Prestamos nuevo, renovado la semana pasada!");
    } else if (selectedFilter === "adelantos") {
      loansResult = loans.filter((loan) => loan.statusText === "Adelanto");
      setFilterDescription("Prestamos con pagos de Adelanto!");
    } else if (selectedFilter === "vencidos") {
      loansResult = loans.filter((loan) => loan.statusText === "Vencido");
      setFilterDescription("Prestamos vencido con balance!");
    }

    setFilteredLoans(loansResult);
  }, [loans, selectedFilter]);

  const getFilteredDebtor = () => {
    if (searchText.length < 3) return filteredLoans;

    const userSearch = indentifySearchType(searchText);

    let filtered = loans;
    filtered = filtered.filter((m) => RemoveAccents(m.loanSearch).toUpperCase().indexOf(userSearch.toUpperCase()) >= 0);
    filtered = filtered.slice(0, 10);

    return filtered;
  };

  const handleSearch = (query) => {
    setSearchText(query);
  };

  const handleFilterLoans = (filter) => {
    handleClose();
    setSelectedFilter(filter);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const loansFilter = getFilteredDebtor(filteredLoans, loans);

  return (
    <div className="mt-3">
      <Container maxWidth="md" disableGutters>
        <Box component="div" p={1}>
          <Grid container>
            <Grid item xs={8}>
              <Typography variant="h5" component="h4" gutterBottom>
                Lista de Cobro!
              </Typography>
            </Grid>

            <Grid item xs={2} align="right">
              <Link to={"/debtors/organize"}>
                <Button className="text-info">
                  <CgUserList size="1.8em" />
                </Button>
              </Link>
            </Grid>
            <Grid item xs={2} align="right">
              <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                {selectedFilter === "xCobrar" ? <FcEmptyFilter size="1.8em" /> : <FcClearFilters size="1.8em" />}
              </Button>
              <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={() => handleFilterLoans("xCobrar")}>
                  {selectedFilter === "xCobrar" && <AiOutlinePushpin className="text-primary" size="1.3em" />}
                  Mostrar X Cobrar
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => handleFilterLoans("new_loan")}>
                  {selectedFilter === "new_loan" && <AiOutlinePushpin className="text-primary" size="1.3em" />}
                  Prestamos Nuevos
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => handleFilterLoans("sale_hoy")}>
                  {selectedFilter === "sale_hoy" && <AiOutlinePushpin className="text-primary" size="1.3em" />}
                  Prestamos Saliente
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => handleFilterLoans("xRenewal")}>
                  {selectedFilter === "xRenewal" && <AiOutlinePushpin className="text-primary" size="1.3em" />}
                  Prestamos Renovo
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => handleFilterLoans("Al Dia")}>
                  {selectedFilter === "Al Dia" && <AiOutlinePushpin className="text-primary" size="1.3em" />}
                  Mostrar Al Dia
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => handleFilterLoans("adelantos")}>
                  {selectedFilter === "adelantos" && <AiOutlinePushpin className="text-primary" size="1.3em" />}
                  Mostrar en Adelantos
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => handleFilterLoans("atrasos")}>
                  {selectedFilter === "atrasos" && <AiOutlinePushpin className="text-primary" size="1.3em" />}
                  Mostrar en Atrasos
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => handleFilterLoans("vencidos")}>
                  {selectedFilter === "vencidos" && <AiOutlinePushpin className="text-primary" size="1.3em" />}
                  Mostrar Vencidos
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Box>

        {/* {!loansFetched && [1, 2, 3, 4, 5, 6].map((n) => <Skeleton key={n} />)} */}
        {!loans && <FullLoadingScreen />}

        <Box component="div" p={1}>
          <SearchBox onChange={handleSearch} placeHolder="Escriba para buscar Prestamo..." value={searchText} />
          <Typography variant="body2" style={{ marginTop: -6, marginBottom: 6 }}>
            <i>{filterDescription}</i>
          </Typography>
        </Box>

        {loans.length === 0 && (
          <div className="container mt-4 mb-5">
            <Typography variant="h5" componet="h3" gutterBottom>
              Lista de Prestamos!
            </Typography>
            <Typography variant="body1" componet="p" paragraph>
              Aqui se muestra la lista de prestamos.
            </Typography>
            <Typography variant="body1" componet="p" paragraph>
              Por el momento, no hay prestamos que mostrar!
            </Typography>
          </div>
        )}

        {loansFilter.map((loan) => (
          // <Grid key={loan.money_id} item xs={12} sm={10} md={6} lg={4} xl={3}>
          <XcobrarLoan key={loan.money_id} loan={loan} />
          // </Grid>
        ))}
      </Container>
    </div>
  );
};

export default XCobrar;
