
import Card from "@nodes/@material-ui/core/Card";
import Typography from "@nodes/@material-ui/core/Typography";
import Box from "@nodes/@material-ui/core/Box";
import PersonIcon from "@nodes/@material-ui/icons/Person";
import { Link } from "@nodes/react-router-dom";
import { printAbsAmount } from "../functions";

const XcobrarLoanList = ({ loan }) => {
  return (
    <Box paddingBottom={1}>
      <Card variant="outlined">
        <table className="table table-sm">
          <tbody>
            <tr>
              <td colSpan="3">
                <Typography variant="subtitle2" component="p" className="font-weight-bold">
                  <Link style={{ color: "#000" }} to={`/loan/detail/${loan.money_id}/${loan.debtor_id}`}>
                    <PersonIcon /> {loan.name}
                  </Link>
                </Typography>
              </td>
            </tr>

            <tr>
              <td style={{ width: "33%" }}>
                <Typography variant="body2" component="p">
                  <span className="badge badge-pill badge-dark pr-1">
                    <span style={{ padding: 2, fontSize: 13 }}>{loan.elapsed}</span>
                  </span>{" "}
                  Monto {printAbsAmount(loan.wPayment)}
                </Typography>
              </td>
              <td style={{ width: "33%" }}>
                <Typography variant="body2" component="p" className="badge badge-pill badge-secondary">
                  <small>xCobrar {printAbsAmount(loan.xCobrar)}</small>
                </Typography>
              </td>
              <td style={{ width: "33%" }}>
                {loan.statusAmount < 0 && (
                  <Typography variant="body2" component="p" className="badge badge-pill badge-info">
                    <small>Adelanto {printAbsAmount(loan.statusAmount)}</small>
                  </Typography>
                )}

                {loan.statusAmount > 0 && (
                  <Typography variant="body2" component="p" className="badge badge-pill badge-warning">
                    <small>Atrasos {printAbsAmount(loan.statusAmount)}</small>
                  </Typography>
                )}

                {loan.statusAmount === 0 && (
                  <Typography variant="body2" component="p" className="badge badge-pill badge-success">
                    <small>Al Dia</small>
                  </Typography>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </Card>
    </Box>
  );
};

export default XcobrarLoanList;
