import { Switch, Route, useHistory } from "react-router-dom";
import Index from "./pages/Index";
import Debtors from "./pages/Debtors";
import ReidyReport from "./pages/ReidyReport";
import Loans from "./pages/Loans";
import DebtorCreate from "./pages/DebtorCreate";
import Profile from "./pages/Profile";
import DebtorEdit from "./pages/DebtorEdit";
import DebtorDelete from "./pages/DebtorDelete";
import LoanCreate from "./pages/LoanCreate";
import LoanCreateAtrasos from "./pages/LoanCreateAtrasos";
import LoanRenewal from "./pages/LoanRenewal";
import LoanSignature from "./pages/LoanSignature";
import PaymentCreate from "./pages/PaymentCreate";
import DeletePayment from "./pages/DeletePayment";
import DeleteLoan from "./pages/DeleteLoan";
import DeleteRoute from "./pages/DeleteRoute";
import DeleteEmployee from "./pages/DeleteEmployee";
import ModifyLoan from "./pages/ModifyLoan";
import Login from "./pages/Login";
import LoanDetail from "./pages/LoanDetail";
import Spenses from "./pages/Spenses";
import Contact from "./pages/Contact";
import PaymentDetails from "./pages/PaymentDetails";
import DebtorsOrganize from "./pages/DebtorsOrganize";
import DebtorProfile from "./pages/DebtorProfile";
import ProtectedRoute from "./components/ProtectedRoute";
import Dashboard from "./pages/Dashboard";
import Cuadre from "./pages/Cuadre";
import Settings from "./pages/Settings";
import AccessCodes from "./pages/AccessCodes";
import Reports from "./pages/Reports";
import UserPhoneVerify from "./pages/UserPhoneVerify";
import Invoice from "./pages/Invoice";
import EmployeeProfile from "./pages/EmployeeProfile";
import RouteCreate from "./pages/RouteCreate";
import SignUp from "./pages/SignUp";
import UploadImage from "./pages/UploadImage";
import RouteWarning from "./pages/RouteWarning";
import Employees from "./pages/Employees";
import EmployeeCreate from "./pages/EmployeeCreate";
import RoleCreate from "./pages/RoleCreate";
import Approval from "./pages/Approval";
import Collected from "./pages/Collected";
import NewLoans from "./pages/NewLoans";
import NewUserContact from "./pages/NewUserContact";
import PasswordNew from "./pages/PasswordNew";
import DebtorImages from "./pages/DebtorImages";
import Caja from "./pages/Caja";
import RedirectPage from "./pages/RedirectPage";
import ChooseRoute from "./pages/ChooseRoute";
import CobroList from "./pages/CobroList";
import LoanContract from "./pages/LoanContract";
import CobroChanges from "./pages/CobroChanges";
import DiagnosticInsert from "./pages/DiagnosticInsert";
import DebtorPaidLoans from "./pages/DebtorPaidLoans";
import MoveDebtor from "./pages/MoveDebtor";
import LoanPayments from "./pages/LoanPayments";
import DebtorLoansCheck from "./pages/DebtorLoansCheck";
import DataCreditoSearch from "./pages/DataCreditoSearch";
import SecurityQuestions from "./pages/SecurityQuestions";
import SecurityVerify from "./pages/SecurityVerify";
import UserPersonal from "./pages/UserPersonal";
import UserUsername from "./pages/UserUsername";
import Referrals from "./pages/Referrals";
import UserLookup from "./pages/UserLookup";
import UserPassword from "./pages/UserPassword";
import PasswordResetMethod from "./pages/PasswordResetMethod";
import PasswordPhoneVerify from "./pages/PasswordPhoneVerify";
import SharedRoutes from "@shared/SharedRoutes";

const Routes = () => {
  let history = useHistory();
  return (
    <Switch>
      <ProtectedRoute path="/referrals" render={(props) => <Referrals {...props} />} />
      <ProtectedRoute path="/user/personal/:id" render={(props) => <UserPersonal {...props} />} />
      <ProtectedRoute path="/user/username/:id/:t?" render={(props) => <UserUsername {...props} />} />
      <ProtectedRoute path="/user/password/:id/:t?" render={(props) => <UserPassword {...props} />} />
      <ProtectedRoute
        path="/employee/delete/:id/:id2"
        render={(props) => <DeleteEmployee {...props} history={history} />}
      />
      <ProtectedRoute path="/employee/:id" render={(props) => <EmployeeProfile {...props} history={history} />} />
      <ProtectedRoute
        path="/debtor/create/:isAtraso?"
        render={(props) => <DebtorCreate {...props} history={history} />}
      />
      <ProtectedRoute path="/loan/payments/:id/:id2" render={(props) => <LoanPayments {...props} />} />
      <ProtectedRoute path="/user/phone/verify" render={(props) => <UserPhoneVerify {...props} />} />

      <ProtectedRoute path="/debtor/loans/check/:dUn/:ced?/:mId?" render={(props) => <DebtorLoansCheck {...props} />} />
      <ProtectedRoute path="/data/credito" render={(props) => <DataCreditoSearch {...props} />} />
      <ProtectedRoute path="/debtor/edit/:id" render={(props) => <DebtorEdit {...props} history={history} />} />
      <ProtectedRoute path="/debtor/delete/:id" render={(props) => <DebtorDelete {...props} history={history} />} />
      <ProtectedRoute path="/debtor/paid/:id" render={(props) => <DebtorPaidLoans {...props} history={history} />} />
      <ProtectedRoute path="/debtor/move/:id" render={(props) => <MoveDebtor {...props} history={history} />} />
      <ProtectedRoute path="/loan/create/:id" render={(props) => <LoanCreate {...props} history={history} />} />
      <ProtectedRoute path="/view/loan/signature/:id/:id2/:id3" render={(props) => <LoanContract {...props} />} />
      <ProtectedRoute
        path="/loan/old/create/:id"
        render={(props) => <LoanCreateAtrasos {...props} history={history} />}
      />
      <ProtectedRoute path="/loan/renewal/:id/:id2" render={(props) => <LoanRenewal {...props} history={history} />} />

      <ProtectedRoute
        path="/debtor/upload/:id/:page?"
        render={(props) => <UploadImage {...props} history={history} />}
      />

      <ProtectedRoute path="/security/questions" render={(props) => <SecurityQuestions {...props} />} />

      <ProtectedRoute
        path="/debtor/view/images/:id/:id2"
        render={(props) => <DebtorImages {...props} history={history} />}
      />

      <ProtectedRoute path="/xcobrar/list" render={(props) => <CobroList {...props} history={history} />} />
      <ProtectedRoute
        path="/xcobrar/changes/:date?"
        render={(props) => <CobroChanges {...props} history={history} />}
      />
      <ProtectedRoute path="/insert/dianostics" render={(props) => <DiagnosticInsert {...props} history={history} />} />
      <ProtectedRoute path="/loan/signature" render={(props) => <LoanSignature {...props} history={history} />} />
      {/* <ProtectedRoute path="/invoice/:id/:id2" render={(props) => <Invoice {...props} history={history} />} /> */}

      <ProtectedRoute path="/loan/detail/:id/:id2" render={(props) => <LoanDetail {...props} history={history} />} />
      <ProtectedRoute path="/loan/delete/:id/:id2" render={(props) => <DeleteLoan {...props} history={history} />} />
      <ProtectedRoute path="/route/delete/:id/:id2" render={(props) => <DeleteRoute {...props} history={history} />} />
      <ProtectedRoute path="/redirect/page/:dUn/:mId/:ced?" render={(props) => <RedirectPage {...props} />} />
      <ProtectedRoute path="/loan/modify/:id/:id2" render={(props) => <ModifyLoan {...props} history={history} />} />
      <ProtectedRoute path="/debtor/profile/:id" render={(props) => <DebtorProfile {...props} history={history} />} />
      <ProtectedRoute
        path="/payment/create/:id/:id2"
        render={(props) => <PaymentCreate {...props} history={history} />}
      />
      <ProtectedRoute path="/payment/details/:id/:id2/:id3" render={(props) => <PaymentDetails {...props} />} />
      <ProtectedRoute path="/access/codes" render={(props) => <AccessCodes {...props} history={history} />} />
      <ProtectedRoute path="/profile" render={(props) => <Profile {...props} history={history} />} />
      <ProtectedRoute path="/route/create" render={(props) => <RouteCreate {...props} history={history} />} />
      <ProtectedRoute path="/spenses" render={(props) => <Spenses {...props} history={history} />} />
      <ProtectedRoute path="/loans" render={(props) => <Loans {...props} />} history={history} />
      <ProtectedRoute path="/debtors/organize" render={(props) => <DebtorsOrganize {...props} history={history} />} />
      <ProtectedRoute path="/debtors" render={(props) => <Debtors {...props} history={history} />} />
      <ProtectedRoute path="/reports" render={(props) => <Reports {...props} history={history} />} />
      <ProtectedRoute path="/employees" render={(props) => <Employees {...props} history={history} />} />
      <ProtectedRoute path="/user/create" render={(props) => <EmployeeCreate {...props} history={history} />} />
      <ProtectedRoute path="/role/create/:id?" render={(props) => <RoleCreate {...props} history={history} />} />
      <ProtectedRoute path="/settings" render={(props) => <Settings {...props} history={history} />} />
      <ProtectedRoute path="/dashboard" render={(props) => <Dashboard {...props} history={history} />} />
      <ProtectedRoute path="/caja" render={(props) => <Caja {...props} history={history} />} />
      <ProtectedRoute path="/cuadre" render={(props) => <Cuadre {...props} history={history} />} />
      <ProtectedRoute path="/collected" render={(props) => <Collected {...props} history={history} />} />
      <ProtectedRoute path="/entregas" render={(props) => <NewLoans {...props} history={history} />} />
      <ProtectedRoute path="/new/user" render={(props) => <NewUserContact {...props} history={history} />} />
      <ProtectedRoute
        path="/delete/payment/:id/:id2/:id3"
        render={(props) => <DeletePayment {...props} history={history} />}
      />
      <ProtectedRoute path="/approval" render={(props) => <Approval {...props} history={history} />} />
      <ProtectedRoute path="/reidy" render={(props) => <ReidyReport {...props} history={history} />} />
      <ProtectedRoute path="/routes/choose" render={(props) => <ChooseRoute {...props} history={history} />} />

      <Route path="/contact" render={(props) => <Contact {...props} />} />
      <Route path="/user/lookup" render={(props) => <UserLookup {...props} />} />
      <Route path="/password/new/:id/:salt/:time" render={(props) => <PasswordNew {...props} />} />
      <Route path="/password/resetmethod" render={(props) => <PasswordResetMethod {...props} />} />
      <Route path="/password/phone/verify" render={(props) => <PasswordPhoneVerify {...props} />} />
      <Route path="/invoice/:id/:id2" render={(props) => <Invoice {...props} history={history} />} />

      <Route path="/route/pending" render={(props) => <RouteWarning {...props} />} />
      <Route path="/security/verify" render={(props) => <SecurityVerify {...props} />} />
      <Route path="/login" render={(props) => <Login {...props} history={history} />} />
      <Route path="/signup/:invitationToken?" render={(props) => <SignUp {...props} history={history} />} />
      <Route path="/" exact render={(props) => <Index {...props} />} />
      <SharedRoutes history={history} />
    </Switch>
  );
};

export default Routes;
