import { useContext, useRef, PureComponent } from "react";
import { Context } from "../context/DataContext";
import { FcPrint } from "react-icons/fc";
// import { AiOutlineFilePdf } from "react-icons/ai";
import Loading from "@shared/components/Loading";
import { UseOwnerLoans } from "@shared/server/fetcher";
import RenderReidyReport from "./RenderReidyReport";
import { useReactToPrint } from "react-to-print";
import "@shared/pages/styles/invoice.css";

export class ComponentToPrint extends PureComponent {
  render() {
    return (
      <div>
        <RenderReidyReport loans={this.props.loans} />
      </div>
    );
  }
}

const ReidyReport = () => {
  const { state } = useContext(Context);
  const { user, route: r2 } = state;

  const pageStyle = `
  @page {
    size: 80mm 50mm;
  }
  @media all {
    .pagebreak {
      display: none;
    }
  }
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
  `;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle,
  });

  const { data: loans, isLoading } = UseOwnerLoans(r2.user_id);

  if (isLoading) return <Loading />;

  return (
    <div className="page-content container mt-3 mb-5">
      <div className="page-header text-blue-d2">
        <div className="page-tools">
          <div className="action-buttons">
            <button className="btn bg-white btn-light mx-1px text-95" onClick={handlePrint}>
              <FcPrint size="1.5em" />
              Imprimir
            </button>
          </div>
        </div>
      </div>

      <ComponentToPrint ref={componentRef} loans={loans} />
    </div>
  );
};

export default ReidyReport;
