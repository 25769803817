import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { printAmount } from "@shared/functions";

const DeleteGastoModal = ({ handleDeleteGasto, gasto }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const submithandler = async (payload) => {
    handleClose(false);
    await handleDeleteGasto(payload);
  };

  return (
    <div>
      <>
        <span className="text-danger" onClick={handleShow}>
          <DeleteForeverIcon />
        </span>

        <Modal show={show} onHide={handleClose} keyboard={false} centered>
          <Modal.Header closeButton>
            <Modal.Title>
              <Typography variant="h5" component="h3" className="text-danger">
                Confirmaci&oacute;n
              </Typography>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Typography variant="h6" component="h4" gutterBottom>
              Decea borrar este gasto?
            </Typography>
            <Typography variant="body2" component="p" paragraph>
              Gasto de {printAmount(gasto.amount)} {gasto.description}
            </Typography>

            <Button size="small" variant="contained" color="secondary" onClick={() => submithandler(gasto)}>
              Borrar Gasto
            </Button>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={handleClose}>
              Cerrar
            </button>
          </Modal.Footer>
        </Modal>
      </>
    </div>
  );
};

export default DeleteGastoModal;
