import { useState, useEffect } from "react";
// import Typography from "@material-ui/core/Typography";
// import { Context } from "../context/DataContext";
import { useParams } from "react-router";
import Box from "@material-ui/core/Box";
// import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
// import CardContent from "@material-ui/core/CardContent";
// import CardHeader from "@material-ui/core/CardHeader";
// import PersonIcon from "@material-ui/icons/Person";
// import NumberFormat from "react-number-format";
// import { Link } from "react-router-dom";
// import { getLoanSelector } from "../context/Selectors";
// import DebtorLoanCard from "@shared/components/DebtorLoanCard";
import DebtorNameHeader from "@shared/components/DebtorNameHeader";
import { getPaymentSelector } from "../context/Selectors";
import RenderPaymentDetail from "@shared/components/RenderPaymentDetail";
import LoanDetailMenu from "@shared/components/LoanDetailMenu";
import { UseLoanPayments } from "@shared/server/fetcher";
import Loading from "@shared/components/Loading";

const PaymentDetails = ({ history }) => {
  // const { state } = useContext(Context);
  const [currentPayment, setCurrentPayment] = useState(null);
  const { id: payment_id, id2: money_id, id3: debtor_id } = useParams();
  // const { user, route } = state;

  const { data: LoanPayments, isLoading } = UseLoanPayments(money_id, debtor_id);

  // const handleDeletePayment = (payload) => {
  //   deletePayment(payload);
  //   history.replace("/collected");
  // };

  useEffect(() => {
    if (LoanPayments) {
      setCurrentPayment(getPaymentSelector(LoanPayments, payment_id));
    }
    //eslint-disable-next-line
  }, [LoanPayments]);

  if (isLoading || !currentPayment) return <Loading />;

  return (
    <div className="mt-3 mb-5">
      <Container maxWidth="md" disableGutters>
        <DebtorNameHeader debtor={currentPayment}>
          <LoanDetailMenu history={history} loan={currentPayment} />
        </DebtorNameHeader>

        <Box component="div" p={1}>
          <RenderPaymentDetail history={history} payment={currentPayment} />
        </Box>
      </Container>
    </div>
  );
};

export default PaymentDetails;
