import { useState } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import { AiOutlineOrderedList } from "react-icons/ai";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import VisibilityIcon from "@material-ui/icons/Visibility";

export default function CollectedPaymentMenu({ history, route, loan, configs }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon fontSize="small" />
      </Button>

      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          className="text-primary"
          onClick={() => history.push(`/loan/detail/${loan.money_id}/${loan.debtor_id}`)}>
          <VisibilityIcon className="pr-1" /> Prestamo Detalle
        </MenuItem>
        <Divider />
        <MenuItem
          // className="text-info"
          onClick={() => history.push(`/loan/payments/${loan.money_id}/${loan.debtor_id}`)}>
          <AiOutlineOrderedList size="1.4em" className="pr-1" /> Historial de Pagos
        </MenuItem>
        <Divider />

        <MenuItem
          className="text-danger"
          onClick={() => history.push(`/delete/payment/${loan.payment_id}/${loan.money_id}/${loan.debtor_id}`)}>
          <DeleteForeverIcon /> Borrar Pago
        </MenuItem>
      </Menu>
    </div>
  );
}
