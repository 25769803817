import { useState, useContext } from "react";
import { Context } from "../context/DataContext";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import Card from "@material-ui/core/Card";
import Chip from "@material-ui/core/Chip";
import AccordionActions from "@material-ui/core/AccordionActions";
import Typography from "@material-ui/core/Typography";
import AccessCodeComponent from "@shared/components/AccessCodeComponent";
import SubmitButton from "@shared/components/SubmitButton";
import { BiRefresh } from "react-icons/bi";
import Grid from "@material-ui/core/Grid";
import Loading from "@shared/components/Loading";
import { UseRouteAccessCodes, UseRoute } from "@shared/server/fetcher";

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(AccordionActions);

export default function CustomizedAccordions() {
  const { state, generateAccessCodes } = useContext(Context);
  const [expanded, setExpanded] = useState("panel1");
  const { route, user } = state;

  const { data: accessCodes, isLoading, mutate } = UseRouteAccessCodes(route.user_id);
  const { data: routeN } = UseRoute(route.creditor_id, user.user_id);

  if (isLoading || !routeN) return <Loading />;

  const handleGenerate = async (type) => {
    const codes = [];
    codes[0] = Math.floor(100000 + Math.random() * 900000);
    // codes[1] = Math.floor(100000 + Math.random() * 900000);
    // codes[2] = Math.floor(100000 + Math.random() * 900000);

    await generateAccessCodes(route.user_id, type, codes);
    mutate([...accessCodes, { code: codes[0], type }]);
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Card className="p-2 m-2">
        <Typography variant="h5" gutterBottom>
          Codigos de Acesos
        </Typography>
        <div className="m-2" id="user-panel" style={{ display: "inline-flex" }}>
          <span className="pr-1">
            <b>
              <Chip label={`R${routeN.creditor_id}`} />
            </b>
          </span>

          <Typography variant="subtitle1" component="p">
            {routeN.route_name} ({routeN.location})
          </Typography>
        </div>
      </Card>

      <Typography variant="h6" gutterBottom></Typography>
      <Accordion square expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Borrar Pagos</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {accessCodes
              .filter((x) => x.type === "deletePayment")
              .map((item) => (
                <Grid key={item.type + item.code} item xs={12} sm={6} md={4}>
                  <AccessCodeComponent key={item.type + item.code} code={item.code} />
                </Grid>
              ))}
          </Grid>
        </AccordionDetails>
        <AccordionActions>
          <SubmitButton
            text="Generar Codigos"
            startIcon={<BiRefresh />}
            color="primary"
            callBack={() => handleGenerate("deletePayment")}
            autoFetch={false}
          />
        </AccordionActions>
      </Accordion>
      <Accordion square expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Cancelar Entrega</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {accessCodes
              .filter((x) => x.type === "deleteNewLoan")
              .map((item) => (
                <Grid key={item.type + item.code} item xs={12} sm={6} md={4}>
                  <AccessCodeComponent key={item.type + item.code} code={item.code} />
                </Grid>
              ))}
          </Grid>
        </AccordionDetails>
        <AccordionActions>
          <SubmitButton
            text="Generar Codigos"
            startIcon={<BiRefresh />}
            color="primary"
            callBack={() => handleGenerate("deleteNewLoan")}
            autoFetch={false}
          />
        </AccordionActions>
      </Accordion>
      <Accordion square expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography>Modificar Entrega</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {accessCodes
              .filter((x) => x.type === "modifyNewLoan")
              .map((item) => (
                <Grid key={item.type + item.code} item xs={12} sm={6} md={4}>
                  <AccessCodeComponent key={item.type + item.code} code={item.code} />
                </Grid>
              ))}
          </Grid>
        </AccordionDetails>
        <AccordionActions>
          <SubmitButton
            text="Generar Codigos"
            startIcon={<BiRefresh />}
            callBack={() => handleGenerate("modifyNewLoan")}
            color="primary"
            autoFetch={false}
          />
        </AccordionActions>
      </Accordion>
      <Accordion square expanded={expanded === "panel22"} onChange={handleChange("panel22")}>
        <AccordionSummary aria-controls="panel22d-content" id="panel22d-header">
          <Typography>Aprobar Prestamo</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {accessCodes
              .filter((x) => x.type === "loanApproval")
              .map((item) => (
                <Grid key={item.type + item.code} item xs={12} sm={6} md={4}>
                  <AccessCodeComponent key={item.type + item.code} code={item.code} />
                </Grid>
              ))}
          </Grid>
        </AccordionDetails>
        <AccordionActions>
          <SubmitButton
            text="Generar Codigos"
            startIcon={<BiRefresh />}
            callBack={() => handleGenerate("loanApproval")}
            color="primary"
            autoFetch={false}
          />
        </AccordionActions>
      </Accordion>

      <Accordion square expanded={expanded === "panel221"} onChange={handleChange("panel221")}>
        <AccordionSummary aria-controls="panel221d-content" id="panel22d-header">
          <Typography>Desbloquear Cuadre</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {accessCodes
              .filter((x) => x.type === "unLockCuadre")
              .map((item) => (
                <Grid key={item.type + item.code} item xs={12} sm={6} md={4}>
                  <AccessCodeComponent key={item.type + item.code} code={item.code} />
                </Grid>
              ))}
          </Grid>
        </AccordionDetails>
        <AccordionActions>
          <SubmitButton
            text="Generar Codigos"
            startIcon={<BiRefresh />}
            callBack={() => handleGenerate("unLockCuadre")}
            color="primary"
            autoFetch={false}
          />
        </AccordionActions>
      </Accordion>
    </div>
  );
}
