import { useContext, useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { string, object } from "yup";
import { Form, RenderInput, RenderDropdown } from "@shared/components/Form";
import { Context } from "../context/DataContext";
import { toast } from "react-toastify";
import Loading from "@shared/components/Loading";
import { UseUser } from "@shared/server/fetcher";
import { useSWRConfig } from "swr";
import MenuItem from "@material-ui/core/MenuItem";
import RenderBackButton from "@shared/components/RenderBackButton";

const RouteCreate = ({ history }) => {
  const { mutate } = useSWRConfig();
  const { createRoute, getJwtToken } = useContext(Context);
  const localUser = getJwtToken();
  const [percentage, setPercent] = useState(30);
  const [weeks, setWeeks] = useState(10);

  const { data: user, isLoading } = UseUser(localUser.user_id);

  if (isLoading) return <Loading />;

  const initialValues = {
    user_id: user.user_id,
    parent_id: user.parent_id,
    ISO: user.ISO,
    routeName: "",
    routePhone: "",
    routePhone2: "",
    address: "",
    routeLocation: "",
    routeCollectDay: "Monday",
  };

  const validation = object({
    routeName: string().required("Nombre de la ruta es requerido!").min(5, "Nombre de la ruta es muy corto!"),
    routePhone: string().required("Numero de telefono es requerido!").min(10, "Numero de telefono invalido!"),
    routePhone2: string().min(10, "Numero de telefono 2 invalido!"),
    routeLocation: string().required("Nombre de la ubicacion es requerido!").min(4, "Nombre de la zona es muy corto!"),
  });

  const button = { label: "Crear Ruta", variant: "contained", color: "primary" };

  const handleChangePercent = (event) => {
    setPercent(event.target.value);
  };

  const handleChangeWeeks = (event) => {
    setWeeks(event.target.value);
  };

  const submithandler = (routeData) => handleRouteCreate(routeData);

  const handleRouteCreate = async (data) => {
    const res = await createRoute({ ...data, percentage, weeks });
    if (res) {
      history.replace("/routes/choose");
      mutate(`/user/linked/routes/${user.user_id}`);
      mutate(`/cobro/data/routes/${user.user_id}`);
      // await changeRoute(creditor_id, user, history);
      toast.success("Ruta fue creada!");
    }
  };

  const percentageOptions = [
    {
      value: "20",
      label: "20%",
    },
    {
      value: "25",
      label: "25%",
    },
    {
      value: "30",
      label: "30%",
    },
    {
      value: "35",
      label: "35%",
    },
  ];

  const weeksOptions = [
    {
      value: "10",
      label: "10 Semanas",
    },
    {
      value: "12",
      label: "12 Semanas",
    },
    {
      value: "13",
      label: "13 Semanas",
    },
    {
      value: "15",
      label: "15 Semanas",
    },
  ];

  return (
    <div className="container mt-4 mb-5">
      <Container maxWidth="md" disableGutters>
        <Typography variant="h5" component="h3" gutterBottom>
          Crear nueva Ruta!
        </Typography>
        <Typography variant="subtitle1" component="p" paragraph>
          Las siguientes informacion son requeridad para crear una ruta.
        </Typography>

        <Form initialValues={initialValues} validation={validation} submithandler={submithandler} button={button}>
          <Box paddingBottom={2}>{RenderInput("routeName", "Nombre de la Empresa", "text", true)}</Box>
          <Box paddingBottom={2}>{RenderInput("routeLocation", "Zona o Ubicacion de la Ruta", "text")}</Box>

          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Box paddingBottom={2}>{RenderInput("routePhone", "Telefono", "number")}</Box>
            </Grid>
            <Grid item xs={6}>
              <Box paddingBottom={2}>{RenderInput("routePhone2", "Telefono 2", "number")}</Box>
            </Grid>
          </Grid>

          <Box paddingBottom={2}>
            {RenderDropdown("routeCollectDay", "Dia de Cobro", [
              { label: "Lunes", value: "Monday" },
              { label: "Martes", value: "Tuesday" },
              { label: "Miercoles", value: "Wednesday" },
              { label: "Jueves", value: "Thursday" },
              { label: "Viernes", value: "Friday" },
              { label: "Sabado", value: "Saturday" },
              { label: "Domingo", value: "Sunday" },
            ])}
          </Box>

          <Box paddingBottom={2}>
            <TextField
              id="standard-select-currency"
              select
              fullWidth
              label="Porcentaje"
              value={percentage}
              onChange={handleChangePercent}
              helperText="Cada nuevo prestamo autom&aacute;ticamente usar&aacute; este Porcentaje!">
              {percentageOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          <Box paddingBottom={2}>
            <TextField
              id="standard-select-currency"
              select
              fullWidth
              label="Cantidad de Cuotas"
              value={weeks}
              onChange={handleChangeWeeks}
              helperText="Cada nuevo prestamo autom&aacute;ticamente usar&aacute; esta cantidad de Cuotas!">
              {weeksOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Form>
        <div className="mt-4">
          <RenderBackButton />
        </div>
      </Container>
    </div>
  );
};

export default RouteCreate;
