import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Form, RenderInput } from "@shared/components/Form";
import { number, object } from "yup";
import CreateIcon from "@material-ui/icons/Create";
import { printAbsAmount } from "@shared/functions";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import PrintIcon from "@material-ui/icons/Print";

const UpdateBankamount = ({ route, data, handleBankUpdate, cashMoney, print, history }) => {
  const [showModal, setShowModal] = useState(false);
  if (!data) return null;

  const initialValues = {
    creditor_id: route.creditor_id,
    current_week: route.today_date,
    bankAmount: data.bank_amount === 0 ? "" : data.bank_amount,
  };

  const validation = object({
    bankAmount: number()
      .required("Cantidad es requerida!")
      .min(0, "Cantidad es muy corta!")
      .max(999999, "Cantidad es muy alta!"),
  });

  const handleCuadrePrint = (efectivo) => {
    const object = {
      route_name: route.route_name,
      location: route.location,
      collected_by: "N / A",
      today_date: data.current_week,
      cuadre_amount: data.totalCuentaXcobrarAllSum,
      cuadre_count: data.cuentaXcobrarAll?.length,
      xCobrar: data.totalCuentaXcobrarSum,
      newLoans: data.newLoansSum,
      bank_amount: data.bank_amount,
      actanotarial: data.actaNoctarialSum,
      gastos: data.gastosSum,
      moras: data.totalMoraSum,
      collected: data.totalCollectedSum,
      efectivo,
    };

    print(object);
    history.push("print/receipt/cuadre/0");
  };

  const button = { label: "Actualizar", variant: "contained", color: "primary" };

  const submithandler = async (data) => {
    await handleBankUpdate(data);
    setShowModal(false);
  };

  return (
    <>
      <Card variant="outlined">
        <CardContent>
          <Grid container>
            <Grid item xs={10}>
              <Typography variant="body1" component="p">
                (+) Caja / Banco:
                {printAbsAmount(data.bank_amount)}
                <span className="p-1 text-primary" onClick={() => setShowModal(!showModal.show)}>
                  <CreateIcon />
                </span>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <PrintIcon onClick={() => handleCuadrePrint(cashMoney)} className="text-info" />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Modal show={showModal} onHide={() => setShowModal(false)} backdrop="static" keyboard={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <Typography variant="h5" className="text-primary">
              Caja / Banco
            </Typography>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container mb-4">
            <Typography variant="body1" paragraph>
              Escriba hacia abajo la cantidad de la caja / banco.
            </Typography>

            <Form initialValues={initialValues} validation={validation} submithandler={submithandler} button={button}>
              <Box paddingBottom={3}>
                {RenderInput("bankAmount", "Cantidad de Banco / Caja", "number", true, "outlined")}
              </Box>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdateBankamount;
