import React, { useEffect } from "react";
import Box from "@nodes/@material-ui/core/Box";
import Typography from "@nodes/@material-ui/core/Typography";
import { useFormikContext } from "@nodes/formik";
import { number, mixed, bool, object } from "@nodes/yup";
import Alert from "@nodes/@material-ui/lab/Alert";
import Grow from "@nodes/@material-ui/core/Grow";
import { Form, RenderInput } from "./Form";
import BasicDatePicker from "./BasicDatePicker";

const GetFormikValues = ({ setValues }) => {
  const { values } = useFormikContext();
  useEffect(() => {
    setValues(values);
  }, [values, setValues]);
  return null;
};

const validation = object({
  percentage: number()
    .required("Porciento es requerido!")
    .min(10, "Porciento es muy bajito!")
    .max(60, "Porciento es muy alto!"),
  npayments: number().required("Cuotas es requerida!").min(5, "Cuotas es muy corta!").max(50, "Cuotas es muy alta!"),
  amount: number().required("Cantidad es requerida!").min(100, "Cantidad es muy bajita!"),
  atrasos: mixed().notOneOf([null, "", 1, 2, 3, 4, 5, 6, 7, 8, 9], "Cantidad de Atraso es Invalida!"),
  actanotarial: number().required("Acta Notarial es requerida!").min(0, "Acta Notarial es muy corta!"),
  // given_date: date().required("Fecha de entrega es requerida!"),
  confirmNewLoan: bool().oneOf([true], "Confirme nueva entrega!"),
});

const RenderNewLoanForm = ({
  setFormValues,
  initialValues,
  route,
  submithandler,
  button,
  givenDate,
  setGivenDate,
  label,
}) => {
  const handleFormSubmit = async (data) => {
    await submithandler(data);
  };

  return (
    <div>
      <Box paddingBottom={2}>
        <Grow direction="up" in>
          <Alert variant="outlined" severity="info">
            <Typography variant="body1" component="p" className="font-weight-bold">
              Digitando prestamo existente!
            </Typography>
          </Alert>
        </Grow>
      </Box>
      <Form initialValues={initialValues} validation={validation} submithandler={handleFormSubmit} button={button}>
        {/* <Box paddingBottom={2}>{RenderInput("given_date", "Fecha de Entrega", "date")}</Box> */}
        <Box paddingBottom={2}>
          <BasicDatePicker date={givenDate} setDate={setGivenDate} label={label} openTo="year" />
        </Box>
        {/* <Box paddingBottom={2}>{RenderInput("start_date", "Fecha de Empiezo", "date")}</Box> */}
        <Box paddingBottom={2}>
          {RenderInput("percentage", "Porciento", "number", false, "standard", "Porciento del prestamo eg: 25 o 30%")}
        </Box>
        <Box paddingBottom={2}>
          {RenderInput("npayments", "Numero de Cuotas", "number", false, "standard", "Cuantas cuotas? eg: 10 o 13")}
        </Box>
        <Box paddingBottom={2}>{RenderInput("amount", "Cantidad", "number")}</Box>
        <Box paddingBottom={2}>{RenderInput("actanotarial", "Acta Notarial", "number")}</Box>

        {route.today_date === route.collect_week_date && (
          <Box paddingBottom={2}>
            <Alert variant="outlined" severity="warning">
              <Typography variant="body2" component="p" className="font-weight-bold">
                Si ahun no cobrado la ruta hoy, debes sumarle el pago de hoy al atrasos.
              </Typography>
            </Alert>
          </Box>
        )}
        <Box paddingBottom={3}>{RenderInput("atrasos", "Atrasos", "number")}</Box>

        <GetFormikValues setValues={setFormValues} />
      </Form>
    </div>
  );
};

export default RenderNewLoanForm;
