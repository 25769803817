import { useContext } from "react";
import { Context } from "../context/DataContext";
import AperturaCard from "../components/AperturaCard";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Card from "@material-ui/core/Card";
import Loading from "@shared/components/Loading";
import FullLoadingScreen from "@shared/components/FullLoadingScreen";
import { UseApertura, UsePastApertura, UseRoute } from "@shared/server/fetcher";
import { useState } from "react";

const Aperturas = () => {
  const { state, changeApertura } = useContext(Context);
  const { user, route: stateRoute } = state;
  const [loading, setLoading] = useState(false);

  const { data: routeN, isLoading: routeLoading } = UseRoute(stateRoute.creditor_id, user.user_id);
  const { data: aperturas, isLoading, mutate } = UseApertura(stateRoute.creditor_id, user.user_id);
  const { data: aperturasPast, isLoading: isLoading2 } = UsePastApertura(stateRoute.creditor_id);

  if (isLoading || isLoading2 || routeLoading) return <Loading />;
  if (loading) return <FullLoadingScreen />;

  const handleChangeApertura = async (week_date, collect_date) => {
    setLoading(true);
    await changeApertura(stateRoute.creditor_id, stateRoute.user_id, week_date, collect_date);
    await mutate();
    setLoading(false);
  };

  // if (import.meta.env.PROD)
  //   return (
  //     <div className="container m-5">
  //       <Typography variant="h5">Pagina en progreso</Typography>
  //     </div>
  //   );

  return (
    <>
      <Card className="p-2 m-2">
        <Typography variant="h5" gutterBottom>
          Cambios de Apertura!
        </Typography>
        <div className="m-2" id="user-panel" style={{ display: "inline-flex" }}>
          <span className="pr-1">
            <b>
              <Chip label={`R${routeN.creditor_id}`} />
            </b>
          </span>

          <Typography variant="subtitle1" component="p">
            {routeN.route_name} ({routeN.location})
          </Typography>
        </div>
      </Card>
      <div className="container mt-3 mb-5">
        <Typography variant="body1" paragraph>
          En esta pagina puede abrir una nueva apertura en los dias de cobro festivo.
        </Typography>

        {aperturas.map((apertura) => (
          <AperturaCard key={apertura.week_date} apertura={apertura} changeApertura={handleChangeApertura} />
        ))}

        {aperturasPast?.length > 0 && (
          <>
            <Typography variant="h5" gutterBottom>
              Historial aperturas pasadas!
            </Typography>

            {isLoading ? (
              <Loading />
            ) : (
              aperturasPast.map((apertura) => (
                <AperturaCard disableDesc key={apertura.week_date} apertura={apertura} changeApertura={console.log} />
              ))
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Aperturas;
