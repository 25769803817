
import { Field } from "@nodes/formik";
import { Select } from "@nodes/formik-material-ui";
import { InputLabel, FormControl, MenuItem } from "@nodes/@material-ui/core";

const Dropdown = ({ name, label, list, defaultProperty, variant }) => {
  return (
    <>
      <FormControl fullWidth>
        <InputLabel htmlFor={name}> {label}</InputLabel>
        <Field
          variant={variant}
          component={Select}
          key={name}
          name={name}
          inputProps={{
            name: name,
            id: name,
          }}>
          {defaultProperty && (
            <MenuItem key={defaultProperty.value} value={defaultProperty.value}>
              {defaultProperty.label}
            </MenuItem>
          )}

          {list.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Field>
      </FormControl>
    </>
  );
};

export default Dropdown;
