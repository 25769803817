
import DatePicker from "@nodes/react-date-picker";
import Box from "@nodes/@material-ui/core/Box";
import { printDate, newDate } from "../functions";

const DatePickerComponent = ({ setCurrentDate, route, date }) => {
  if (!date) return null;
  const handleChange = (pickedDate) => {
    if (!pickedDate) return;
    setCurrentDate(printDate(pickedDate, "yyyy-MM-dd"));
  };

  return (
    <Box component="div" p={1}>
      <DatePicker
        onChange={handleChange}
        value={newDate(date)}
        clearIcon={null}
        calendarIcon={null}
        locale={route.route_location === "1" ? "en" : "es"}
        showLeadingZeros
      />
    </Box>
  );
};

export default DatePickerComponent;
