import { useContext, useState } from "react";
import { object } from "yup";
import { Form } from "@shared/components/Form";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { Context } from "../context/DataContext";
import SignatureDialog from "../components/SignatureDialog";
import Box from "@material-ui/core/Box";
import NumberFormat from "react-number-format";
import { Redirect } from "react-router-dom";

const LoanSignature = ({ history }) => {
  const { state, createLoan } = useContext(Context);
  const [signature, setSignature] = useState(null);

  if (!state.temporaryLoanData) return <Redirect to="/" />;

  const initialValues = {
    ...state.temporaryLoanData,
    signature,
  };

  const validation = object({});

  const button = { label: "Guardar Prestamo", variant: "contained", color: "primary" };
  const submithandler = (data) => createLoan(data, history.replace);

  return (
    <div className="mt-4 mb-5">
      <Container maxWidth="md">
        <Typography variant="h5" component="h3" gutterBottom>
          Firma del Cliente
        </Typography>

        <Typography variant="h6" component="h4" gutterBottom>
          {state.temporaryLoanData.name}
        </Typography>

        <Typography variant="subtitle1" component="p" gutterBottom>
          Detalle de Prestamo
        </Typography>

        <Typography variant="body1" component="p" gutterBottom>
          Monto Prestado:{" "}
          <NumberFormat
            value={state.temporaryLoanData.amount}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          Balance Antiguo:{" "}
          <NumberFormat
            value={state.temporaryLoanData.payoffBalance}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
        </Typography>

        <Typography variant="body1" component="p" gutterBottom>
          Acta Notarial:{" "}
          <NumberFormat
            value={state.temporaryLoanData.actanotarial}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
        </Typography>

        <Typography variant="body1" component="p" gutterBottom>
          Pago de Adelanto:{" "}
          <NumberFormat
            value={state.temporaryLoanData.adelanto}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
        </Typography>

        <Typography variant="body1" component="p" gutterBottom>
          Monto Entregado:{" "}
          <NumberFormat
            value={
              state.temporaryLoanData.amount -
              state.temporaryLoanData.payoffBalance -
              state.temporaryLoanData.actanotarial -
              state.temporaryLoanData.adelanto
            }
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
          />
        </Typography>

        <Box m={1} paddingTop={2}>
          <SignatureDialog signature={signature} setSignature={setSignature} name={state.temporaryLoanData.name} />
        </Box>

        {signature && (
          <Form
            initialValues={initialValues}
            validation={validation}
            submithandler={submithandler}
            button={button}></Form>
        )}
      </Container>
    </div>
  );
};

export default LoanSignature;
