import React from "react";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import { GiOpenChest } from "react-icons/gi";
// import CircularProgress from "@material-ui/core/CircularProgress";
import { newDate, printAmount, printDate } from "@shared/functions";

const RenderLinkedRoute = ({ route, linkedRoutesData, handleRouteChange }) => {
  return (
    <Box key={route.creditor_id} paddingBottom={1}>
      <Card raised>
        <CardContent>
          <Typography variant="h6" component="h4" gutterBottom>
            {route.route_name} ({route.location})
          </Typography>

          {route.isCobro === "1" ? (
            <>
              <Typography variant="h6" component="h4">
                <GiOpenChest className="text-danger" /> Estado de la Ruta ({linkedRoutesData[route.creditor_id]?.count}
                ): <span>{printAmount(linkedRoutesData[route.creditor_id]?.amount)}</span>
              </Typography>
              <Typography variant="body1" className="text-danger" component="h4" gutterBottom>
                Cuadre de la App de cobro abierto!
              </Typography>
            </>
          ) : (
            <Typography variant="h6" component="h4" gutterBottom>
              Estado de la Ruta ({linkedRoutesData[route.creditor_id]?.count}):{" "}
              <span>{printAmount(linkedRoutesData[route.creditor_id]?.amount)}</span>
            </Typography>
          )}

          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Typography variant="body1" component="h4" gutterBottom>
                Prestamos {linkedRoutesData[route.creditor_id]?.loanCount}
              </Typography>
            </Grid>

            <Grid item xs={7}>
              <Typography variant="body1" component="h4" gutterBottom>
                Dia de Cobro{" "}
                <span
                  className={
                    linkedRoutesData[route.creditor_id]?.today_day === linkedRoutesData[route.creditor_id]?.collect_day
                      ? "badge badge-pill badge-success"
                      : "badge badge-pill badge-secondary"
                  }>
                  <b> {linkedRoutesData[route.creditor_id]?.sp_collect_day}</b>
                </span>
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={5}>
              <Typography variant="body1" component="h4" gutterBottom>
                Clientes {linkedRoutesData[route.creditor_id]?.debtorCount}
              </Typography>
            </Grid>

            <Grid item xs={7}>
              <Typography variant="body1" component="h4" gutterBottom>
                Creada en {printDate(newDate(linkedRoutesData[route.creditor_id]?.created_date), "dd-MMM-y")}
              </Typography>
            </Grid>
          </Grid>

          <Divider />
        </CardContent>
        <div className="p-1 text-right">
          <Button size="small" variant="outlined" color="primary" onClick={() => handleRouteChange(route)}>
            Ver esta Ruta!
          </Button>
        </div>
      </Card>
    </Box>
  );
};

export default RenderLinkedRoute;
