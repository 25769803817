import { useContext, useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { Context } from "../context/DataContext";

const PasswordResetMethod = ({ history }) => {
  const { state } = useContext(Context);
  const [resetMethod, setResetMethod] = useState("sms");

  useEffect(() => {
    if (state.passwordReset) {
      if (state.passwordReset.verified === "0") {
        setResetMethod("questions");
      }
    }
  }, [state.passwordReset]);

  if (!state.passwordReset || (state.passwordReset.questionSet === "0" && state.passwordReset.verified === "0"))
    return <Redirect to="/" />;

  const handleMethodChange = (e) => {
    setResetMethod(e.target.value);
  };

  const navigateUser = () => {
    const redirect = resetMethod === "sms" ? "/password/phone/verify" : "/security/verify";
    history.replace(redirect);
  };

  return (
    <div className="container mt-5 mb-5">
      <Typography variant="h5" component="h3" gutterBottom>
        Restablecer Contraña!
      </Typography>
      <Typography variant="subtitle1" component="p" paragraph>
        Por su seguridad, selecione un metodo para verificar su cuenta.
      </Typography>

      <Divider className="mb-3" />

      <FormGroup row>
        <Box paddingBottom={2}>
          <FormControlLabel
            control={
              <Switch
                disabled={state.passwordReset.verified === "0" ? true : false}
                checked={resetMethod === "sms" ? true : false}
                onChange={handleMethodChange}
                name={"resetMethod"}
                value={"sms"}
              />
            }
            label="Mensaje de Texto - Enviar mensaje de Texto a mi telefono."
          />
          {state.passwordReset.verified === "0" && <p className="text-danger">Esta opcion no esta disnoblie!</p>}
        </Box>
        <Box paddingBottom={0}>
          <FormControlLabel
            control={
              <Switch
                disabled={state.passwordReset.questionSet === "0" ? true : false}
                checked={resetMethod !== "sms" ? true : false}
                onChange={handleMethodChange}
                name={"resetMethod"}
                value={"questions"}
              />
            }
            label="Preguntas de Seguridad - Contestar mi pregunta de Seguridad."
          />
          {state.passwordReset.questionSet === "0" && <p className="text-danger">Esta opcion no esta disnoblie!</p>}
        </Box>
      </FormGroup>

      <Box paddingTop={4}>
        <Button fullWidth variant="outlined" color="primary" onClick={navigateUser}>
          Continuar
        </Button>
      </Box>

      <br />
    </div>
  );
};

export default PasswordResetMethod;
