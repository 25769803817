import { useContext } from "react";
import { Context } from "../context/DataContext";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
// import Typography from "@material-ui/core/Typography";
// import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { BiLogOut } from "react-icons/bi";
import RenderUserProfile from "@shared/components/RenderUserProfile";
import DisplayAppEnvName from "@shared/components/DisplayAppEnvName";
import { UseUser } from "@shared/server/fetcher";
import Loading from "@shared/components/Loading";
// import pkg from "../../package.json";

const Profile = ({ history }) => {
  const { state, logout } = useContext(Context);
  const { user: user2 } = state;

  const { data: user, isLoading } = UseUser(user2.user_id);

  if (isLoading) return <Loading />;

  return (
    <div className="mt-4 mb-5">
      <Container maxWidth="md" disableGutters>
        <RenderUserProfile history={history} user={user} />

        <Container maxWidth="xs">
          <Button
            startIcon={<BiLogOut />}
            variant="outlined"
            color="secondary"
            fullWidth
            onClick={() => logout(() => history.replace("/login"))}>
            Salir
          </Button>
        </Container>
        <br />
        <br />
        <DisplayAppEnvName />
      </Container>
    </div>
  );
};

export default Profile;
