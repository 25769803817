import { useState, useContext } from "react";
import { Context } from "../context/DataContext";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
// import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Divider from "@material-ui/core/Divider";
import MenuIcon from "@material-ui/icons/Menu";
// import AccountCircle from "@material-ui/icons/AccountCircle";
// import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";
// import MailIcon from "@material-ui/icons/Mail";
import MUIDrawer from "@material-ui/core/Drawer";
// import List from "@material-ui/core/List";
import MenuList from "@material-ui/core/MenuList";
import Button from "@material-ui/core/Button";
// import ListItem from "@material-ui/core/ListItem";
import Brightness7Icon from "@material-ui/icons/Brightness7";
import Brightness6Icon from "@material-ui/icons/Brightness6";
import ListItemIcon from "@material-ui/core/ListItemIcon";
// import CheckIcon from "@material-ui/icons/Check";
import BrightnessAutoIcon from "@material-ui/icons/BrightnessAuto";
// import GroupAddIcon from "@material-ui/icons/GroupAdd";
import ListItemText from "@material-ui/core/ListItemText";
import { DrawerList } from "./DrawerList";
// import { GiOpenChest } from "react-icons/gi";
import { Link } from "react-router-dom";
// import LoginDialog from "./LoginDialog";
import RenderTestUserWarning from "@shared/components/RenderTestUserWarning";
import InternetConection from "@shared/components/InternetConection";
import Loading from "@shared/components/Loading";
import { UseRoute } from "@shared/server/fetcher";
import CustomerBalance from "@shared/components/CustomerBalance";
import {
  AiOutlineUsergroupAdd,
  AiOutlineQuestionCircle,
  AiOutlinePoweroff,
  AiOutlineDollarCircle,
} from "react-icons/ai";
// import {  AiOutlineMail } from "react-icons/ai";
import { FaRegUser } from "react-icons/fa";
// import { IoMdNotificationsOutline } from "react-icons/io";
import InstallPWA from "@shared/hooks/InstallPwa";
// import ChangeRouteModal from "./ChangeRouteModal";
import BluetoothIcon from "@material-ui/icons/Bluetooth";
import { BiLogIn } from "@nodes/react-icons/bi";
import DisplayAppEnvName from "@shared/components/DisplayAppEnvName";

const useStyles = makeStyles((theme) => ({
  drawerHeader: {
    paddingLeft: 5,
    paddingRight: 10,
    paddingBottom: 10,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  selected: {
    backgroundColor: "#7f8691 !important",
    color: "white",
    fontWeight: 600,
  },

  title: {
    flexGrow: 1,
  },

  inputRoot: {
    color: "inherit",
  },

  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function PrimarySearchAppBar({ history }) {
  const { state, toggleDarkMode, logout } = useContext(Context);
  const { user, route } = state;

  const { data: routeN, isLoading } = UseRoute(route.creditor_id, user.user_id);

  const classes = useStyles();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [mobileMoreAnchorEl2, setMobileThemeAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isMobileMenuOpen2 = Boolean(mobileMoreAnchorEl2);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileThemeMenuClose = () => {
    setMobileThemeAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileThemeMenuOpen = (event) => {
    setMobileThemeAnchorEl(event.currentTarget);
  };

  const handleMobileNavigation = (to) => {
    handleMobileMenuClose();
    if (to === "/login") {
      logout(() => history.replace("/login"));
    } else {
      history.push(to);
    }
  };

  const handleMobileThemeClicks = () => {
    toggleDarkMode();
    handleMobileThemeMenuClose();
  };

  // const handleNavigation = (to) => {
  //   toggleDrawer();
  //   history.push(to);
  // };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const menuId = "primary-search-account-menu";

  const custBalUrl = route?.user_id ? `/customer/balance/${route.user_id}/1` : `/customer/balance/0/1`;

  const mobileMenuId = "primary-search-account-menu-mobile";
  const mobileMenuId2 = "primary-search-account-menu-mobile2";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}>
      {/* <MenuItem>
        <IconButton aria-label="show 0 new mails" color="inherit">
          <Badge badgeContent={0} color="secondary">
            <AiOutlineMail />
          </Badge>
        </IconButton>
        Mensajes
      </MenuItem>

      <MenuItem>
        <IconButton aria-label="show 0 new notifications" color="inherit">
          <Badge badgeContent={0} color="secondary">
            <IoMdNotificationsOutline />
          </Badge>
        </IconButton>
        Notificaciones
      </MenuItem> */}

      {user.user_id === 0 && (
        <MenuItem onClick={() => handleMobileNavigation("/login")}>
          <IconButton aria-label="show 0 new notifications" color="inherit">
            <BiLogIn />
          </IconButton>
          Iniciar Sesion
        </MenuItem>
      )}

      <MenuItem onClick={() => handleMobileNavigation("/quickprinter")}>
        <IconButton aria-label="show 0 new notifications" color="inherit">
          <BluetoothIcon />
        </IconButton>
        Conectar Impresora
      </MenuItem>

      {user.user_id !== 0 && (
        <MenuItem onClick={() => handleMobileNavigation("/referrals")}>
          <IconButton aria-label="show 0 new referrals" color="inherit">
            <AiOutlineUsergroupAdd size="1em" />
          </IconButton>
          Referir un amigo
        </MenuItem>
      )}

      <MenuItem onClick={() => handleMobileNavigation(custBalUrl)}>
        <IconButton aria-label="show 0 new referrals" color="inherit">
          <AiOutlineDollarCircle size="1em" />
        </IconButton>
        Mi Factura
      </MenuItem>

      {user.user_id !== 0 && (
        <MenuItem onClick={() => handleMobileNavigation("/profile")}>
          <IconButton
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit">
            <FaRegUser size="0.8em" />
          </IconButton>
          Mi Perfil
        </MenuItem>
      )}

      <Divider />

      {user.user_id !== 0 && (
        <MenuItem className="text-danger" onClick={() => handleMobileNavigation("/login")}>
          <IconButton
            aria-label="logout"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit">
            <AiOutlinePoweroff size="0.8em" />
          </IconButton>
          Salir
        </MenuItem>
      )}

      <Divider />

      <MenuItem onClick={() => handleMobileNavigation("/contact")}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit">
          <AiOutlineQuestionCircle />
        </IconButton>
        Contactarme
      </MenuItem>
    </Menu>
  );

  const renderThemeMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl2}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId2}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen2}
      onClose={handleMobileThemeMenuClose}>
      <MenuItem onClick={() => handleMobileThemeClicks("light")}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit">
          <Brightness6Icon />
        </IconButton>
        <p>
          Color Claro{" "}
          {/* {state.device.darkMode === false && (
            <small>
              <CheckIcon />
            </small>
          )} */}
        </p>
      </MenuItem>
      <MenuItem onClick={() => handleMobileThemeClicks("dark")}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit">
          <Brightness7Icon />
        </IconButton>

        <p>
          Color Oscuro{" "}
          {/* {state.device.darkMode === true && (
            <small>
              <CheckIcon />
            </small>
          )} */}
        </p>
      </MenuItem>
      <MenuItem onClick={() => handleMobileThemeClicks("/profile")}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit">
          <BrightnessAutoIcon />
        </IconButton>

        <p>Automatico</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <InternetConection />
      <AppBar style={{ marginLeft: -1, marginTop: -1 }} position="static">
        {user.user_id !== 0 ? (
          <Toolbar>
            <IconButton
              onClick={toggleDrawer}
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer">
              <MenuIcon />
            </IconButton>
            <Typography onClick={() => history.push("/dashboard")} className={classes.title} variant="h6" noWrap>
              WPrestamos
            </Typography>

            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              {/*
              <IconButton edge="end" aria-label="show 0 new mails" color="inherit">
              <Badge badgeContent={0} color="secondary">
              <AiOutlineMail />
              </Badge>
              </IconButton>
              <IconButton edge="end" aria-label="show 0 new notifications" color="inherit">
              <Badge badgeContent={0} color="secondary">
              <IoMdNotificationsOutline />
                </Badge>
              </IconButton> */}

              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={() => history.push("/quickprinter")}
                color="inherit">
                <BluetoothIcon />
              </IconButton>

              <IconButton
                edge="end"
                onClick={() => history.push("/referrals")}
                aria-label="show 0 new referrals"
                color="inherit">
                <AiOutlineUsergroupAdd size="1em" />
              </IconButton>

              <IconButton
                edge="end"
                onClick={() => history.push(custBalUrl)}
                aria-label="show 0 new referrals"
                color="inherit">
                <AiOutlineDollarCircle size="1em" />
              </IconButton>

              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={() => history.push("/profile")}
                color="inherit">
                <FaRegUser size="0.8em" />
              </IconButton>

              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={() => history.push("/contact")}
                color="inherit">
                <AiOutlineQuestionCircle />
              </IconButton>
            </div>

            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit">
                <MoreIcon />
              </IconButton>
            </div>

            <div>
              <IconButton
                edge="end"
                aria-label="Admin Panel"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleMobileThemeMenuOpen}
                color="inherit">
                {state.device.darkMode === true ? <Brightness7Icon /> : <Brightness6Icon />}
              </IconButton>
            </div>
          </Toolbar>
        ) : (
          <Toolbar>
            <Typography onClick={() => history.push("/")} className={classes.title} variant="h6" noWrap>
              Bienvenido
            </Typography>

            {/* <LoginDialog history={history} /> */}
            {/* <Button variant="outlined" color="inherit" size="small" onClick={() => history.push("/login")}>
              Iniciar Sesion
            </Button> */}
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              {/*
              <IconButton edge="end" aria-label="show 0 new mails" color="inherit">
              <Badge badgeContent={0} color="secondary">
              <AiOutlineMail />
              </Badge>
              </IconButton>
              <IconButton edge="end" aria-label="show 0 new notifications" color="inherit">
              <Badge badgeContent={0} color="secondary">
              <IoMdNotificationsOutline />
                </Badge>
              </IconButton> */}

              {+user.user_id === 0 && (
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={() => history.push("/login")}
                  color="inherit">
                  <BiLogIn />
                </IconButton>
              )}

              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={() => history.push("/quickprinter")}
                color="inherit">
                <BluetoothIcon />
              </IconButton>

              {+user.user_id !== 0 && (
                <IconButton
                  edge="end"
                  onClick={() => history.push("/referrals")}
                  aria-label="show 0 new referrals"
                  color="inherit">
                  <AiOutlineUsergroupAdd size="1em" />
                </IconButton>
              )}

              <IconButton
                edge="end"
                onClick={() => history.push(custBalUrl)}
                aria-label="show 0 new referrals"
                color="inherit">
                <AiOutlineDollarCircle size="1em" />
              </IconButton>

              {+user.user_id !== 0 && (
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={() => history.push("/profile")}
                  color="inherit">
                  <FaRegUser size="0.8em" />
                </IconButton>
              )}

              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={() => history.push("/contact")}
                color="inherit">
                <AiOutlineQuestionCircle />
              </IconButton>
            </div>

            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit">
                <MoreIcon />
              </IconButton>
            </div>

            <div>
              <IconButton
                edge="end"
                aria-label="Admin Panel"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleMobileThemeMenuOpen}
                color="inherit">
                {state.device.darkMode === true ? <Brightness7Icon /> : <Brightness6Icon />}
              </IconButton>
            </div>
          </Toolbar>
        )}
      </AppBar>

      {user.user_id !== 0 && (
        <>
          <CustomerBalance history={history} owner_id={route.user_id} mainOnly={1} />
          <RenderTestUserWarning route={route} />
        </>
      )}

      {+routeN?.isCobro === 1 && (
        <div className="text-center mt-2">
          <Typography variant="body1" className="text-danger" component="h4" gutterBottom>
            Cuadre de la App de cobro abierto!
          </Typography>
        </div>
      )}

      <DisplayAppEnvName avoidProd={true} displayLabel={false} />

      <MUIDrawer
        className={classes.drawer}
        anchor="left"
        open={open}
        onClose={toggleDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}>
        <div className={classes.drawerHeader}>
          <div className="text-center">
            <img
              alt="app-logo"
              style={{ width: 165, height: 93, marginBottom: 20, marginTop: 15 }}
              src="/../../full-logo-copy.png"
            />
          </div>

          {isLoading ? (
            <Loading />
          ) : (
            <>
              <Typography variant="body2">{routeN.route_name}</Typography>
              <Typography variant="body1">Zona: {routeN.location}</Typography>
              <Typography variant="body1">Usuario: {user.firstname}</Typography>
            </>
          )}
          <Typography className="mt-1" variant="body2" align="right">
            <Link onClick={toggleDrawer} to="/routes/choose">
              Ver mis Rutas!
            </Link>
          </Typography>

          {/* <ChangeRouteModal history={history} toggleDrawer={toggleDrawer} /> */}
        </div>
        <Divider />

        <MenuList>
          <div onClick={() => toggleDrawer()}>
            {DrawerList.map((item, index) => {
              return (
                <div key={index}>
                  <MenuItem
                    component={Link}
                    to={item.path}
                    classes={{ selected: classes.selected }}
                    selected={history.location.pathname === item.path}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.title} />
                  </MenuItem>
                </div>
              );
            })}
          </div>
        </MenuList>
      </MUIDrawer>
      <InstallPWA />
      {renderMobileMenu}
      {renderThemeMenu}
    </div>
  );
}
