import { useState } from "react";
import { Dropdown, Button, ButtonGroup } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";

const RolesDropdown = ({ roles = [], user = {}, creditor_id, handleSetUser }) => {
  const [isLoading, setLoading] = useState(false);
  const handleClick = async (user, role, creditor_id) => {
    setLoading(true);
    await handleSetUser(user, role, creditor_id);
    setLoading(false);
  };

  const variant = !user.role_id || user.is_active === "0" ? "secondary" : user.color;

  return (
    <>
      {isLoading ? (
        <CircularProgress size="1.5rem" />
      ) : (
        <>
          <Dropdown size="sm" as={ButtonGroup}>
            <Button size="sm" variant={variant}>
              {user.role_id && user.is_active === "1" ? <small>{user.role_name}</small> : <span>No Activo</span>}
            </Button>

            <Dropdown.Toggle split variant={variant} id="dropdown-split-basic" />

            <Dropdown.Menu>
              {roles.map((role, index) => (
                <Dropdown.Item key={role.role_id} onClick={() => handleClick(user, role, creditor_id)}>
                  {role.role_name}
                  {index < roles.length - 1 && <Dropdown.Divider />}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </>
      )}
    </>
  );
};

export default RolesDropdown;
