import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import RenderContactInfo from "@shared/components/RenderContactInfo";

const NewUserContact = () => {
  const history = useHistory();
  return (
    <div className="container mt-5">
      <Typography variant="h6" gutterBottom>
        Gracias por tratar WPrestamos!
      </Typography>

      <Typography variant="body1" className="text-info">
        A continuacion esta mi informacion de contacto, por favor guarde mi numero para que pueda escribir me si tiene
        alguna pregunta.
      </Typography>

      <Grid item xs={12} lg={5}>
        <Card variant="outlined" className="mt-5">
          <Box m={2}>
            <RenderContactInfo />
          </Box>
        </Card>
      </Grid>

      <Box p={1}>
        <Button fullWidth variant="outlined" color="primary" onClick={() => history.push("/routes/choose")}>
          Continuar!
        </Button>
      </Box>
    </div>
  );
};

export default NewUserContact;
