import { useContext } from "react";
import { useParams } from "react-router";
import { Context } from "../context/DataContext";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import { printAmount } from "@shared/functions";
import SubmitButton from "@shared/components/SubmitButton";
import CancelIcon from "@material-ui/icons/Cancel";
import DebtorNameHeader from "@shared/components/DebtorNameHeader";
import Loading from "@shared/components/Loading";
import { UseLoan, UseRoute } from "@shared/server/fetcher";
import RenderLoanDeleteDetail from "../components/RenderLoanDeleteDetail";
import { useSWRConfig } from "swr";
import RenderBackButton from "@shared/components/RenderBackButton";

const DeleteLoan = ({ history }) => {
  const { mutate } = useSWRConfig();
  const { state, loanDelete } = useContext(Context);
  const { route, user } = state;

  const { id: money_id, id2: debtor_id } = useParams();
  const { data: loan, isLoading } = UseLoan(money_id, debtor_id, route.creditor_id, user.user_id);
  const { data: routeN, isLoading: routeLoading } = UseRoute(route.creditor_id, user.user_id);

  if (isLoading || routeLoading) return <Loading />;

  const submithandler = async () => {
    await loanDelete(loan.money_id, loan.mUniqueId, route.creditor_id, user.user_id, routeN.isCobro, history);

    mutate(`/loans/${route.creditor_id}/${user.user_id}`);
    mutate(`/xcobrar/loans/${route.creditor_id}/${user.user_id}`);
  };

  return (
    <div className="container mt-5">
      <DebtorNameHeader debtor={loan} history={history} />
      <Card>
        <CardContent>
          <Typography variant="h5" component="h3" className="text-danger">
            Confirmaci&oacute;n
          </Typography>

          <Typography variant="h6" component="h4" gutterBottom>
            Decea borrar este Prestamo?
          </Typography>

          <RenderLoanDeleteDetail loan={loan} />

          <Box paddingTop={4}>
            <SubmitButton
              autoFetch={false}
              color="secondary"
              text="Cancelar Prestamo!"
              startIcon={<CancelIcon />}
              callBack={submithandler}
            />
          </Box>
          <div className="mt-4">
            <RenderBackButton />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default DeleteLoan;
