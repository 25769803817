import { useContext } from "react";
import { useHistory } from "react-router-dom";
import Drawer from "./components/Drawer";
// import ChangeRouteModal from "./components/ChangeRouteModal";
import { ToastContainer } from "react-toastify";
import Snackbar from "./components/Snackbar";
import ResolveAuth from "./pages/ResolveAuth";
// import { hot } from "react-hot-loader";
import Paper from "@material-ui/core/Paper";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { Context } from "./context/DataContext";
import "react-toastify/dist/ReactToastify.css";
// import { withProfiler } from "@sentry/react";
import Routes from "./Routes";
// import StickyState from "@shared/components/StickyState.jsx";

const App = () => {
  let history = useHistory();
  const { state } = useContext(Context);
  // const [route, setRoute] = StickyState({}, "route");

  const lightTheme = createTheme({
    palette: {
      primary: {
        main: "#23569e",
      },
    },
  });

  const darkTheme = createTheme({
    palette: {
      primary: {
        main: "#2c2c2c",
      },
      type: "dark",
    },
  });

  return (
    <ThemeProvider theme={state.device.darkMode ? darkTheme : lightTheme}>
      <Paper variant="outlined" style={{ minHeight: "100vh" }}>
        <ResolveAuth history={history} />
        <Drawer history={history} />
        <ToastContainer />
        <Snackbar />

        <Routes history={history} />
      </Paper>
    </ThemeProvider>
  );
};

// export default withProfiler(App);
export default App;
