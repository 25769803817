import { useState } from "react";
import { MdOutlineFavoriteBorder, MdOutlineFavorite } from "react-icons/md";

import CircularProgress from "@material-ui/core/CircularProgress";

const FavoriteDebtor = ({ debtor, toggleFaveDebtor, mutate }) => {
  const [loading, setLoading] = useState("idle");
  const handleClick = async () => {
    setLoading("loading");
    await toggleFaveDebtor(debtor);
    await mutate();
    setLoading("completed");
  };

  return (
    <>
      {loading === "loading" ? (
        <CircularProgress className="mt-2" size="1.3em" />
      ) : (
        <div onClick={handleClick}>
          {+debtor.fave === 1 ? (
            <MdOutlineFavorite size="1.5em" className="mt-2 text-success" />
          ) : (
            <MdOutlineFavoriteBorder size="1.5em" className=" mt-2" />
          )}
        </div>
      )}
    </>
  );
};

export default FavoriteDebtor;
