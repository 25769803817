import { AiFillPhone } from "@nodes/react-icons/ai";
import { BsFillCircleFill } from "@nodes/react-icons/bs";
import { ImUser } from "@nodes/react-icons/im";
import "./styles/invoice.css";
import { newDate, printAmount, printDate, formatPhoneNumber } from "../functions";

const RenderInvoice = ({ data }) => {
  if (!data) return null;

  const currentBalance = data.routes.reduce((acc, obj) => {
    return acc + +obj.dueAmount;
  }, 0);

  const payAmount = currentBalance + +data.invoice.prevBal - +data.invoice.paidCredit - +data.invoice.paidReferral;
  return (
    <div className="page-content container mt-3 mb-5">
      <div className="page-header text-blue-d2">
        <h1 className="page-title text-secondary-d1">
          Factura
          <small className="page-info">
            <i className="fa fa-angle-double-right text-80"></i> # {data.invoice.invoice_id}
          </small>
        </h1>
      </div>

      <div className="container px-0">
        <div className="row mt-4">
          <div className="col-12 col-lg-12">
            <div className="row">
              <div className="col-12">
                <div className="text-center text-150">
                  <div className="mt-2 mb-1 text-center">
                    <img alt="" style={{ width: 165, height: 93 }} src="/../../full-logo-copy.png" />
                  </div>
                </div>
              </div>
            </div>

            <hr className="row brc-default-l1 mx-n1 mb-4" />

            <div className="row">
              <div className="col-sm-6">
                <div>
                  <span className="text-600 text-110 text-blue align-middle">
                    {data.invoice.fname} {data.invoice.lname}
                  </span>
                </div>
                <div className="text-grey-m2">
                  <div className="my-1">
                    <ImUser /> <b className="text-600">Cliente W{data.invoice.customer_id}</b>
                  </div>
                </div>
                <div className="text-grey-m2">
                  <div className="my-1">
                    <AiFillPhone /> <b className="text-600">{formatPhoneNumber(data.invoice.phone)}</b>
                  </div>
                </div>
              </div>

              <div className="text-95 col-sm-6 align-self-start d-sm-flex justify-content-end">
                <hr className="d-sm-none" />
                <div className="text-grey-m2">
                  <div className="mt-1 mb-2 text-secondary-m1 text-600 text-125 font-weight-bold">Factura</div>

                  <div className="my-2">
                    <BsFillCircleFill size="0.9em" style={{ color: "#84B0CA" }} />{" "}
                    <span className="text-600 text-90">ID #: </span>
                    {data.invoice.invoice_id}
                  </div>

                  <div className="my-2">
                    <BsFillCircleFill size="0.9em" style={{ color: "#84B0CA" }} />{" "}
                    <span className="text-600 text-90">Fecha: </span> {printDate(newDate(data.invoice.invoiceDate))}
                  </div>

                  <div className="my-2">
                    <BsFillCircleFill size="0.9em" style={{ color: "#84B0CA" }} />{" "}
                    <span className="text-600 text-90">Estado: </span>
                    {data.invoice.paidAmount < payAmount ? (
                      <span className="badge badge-warning badge-pill px-25">Sin pagar</span>
                    ) : (
                      <span className="badge badge-success badge-pill px-25">Pagado</span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-4">
              <div className="table-responsive">
                <table className="table table-striped table-borderless border-0 border-b-2 brc-default-l1">
                  <thead className="bg-none bgc-default-tp1">
                    <tr className="text-white">
                      <th className="opacity-2">#</th>
                      <th className="d-none d-md-table-cell">Ref</th>
                      <th>Ruta</th>
                      <th>Zona</th>
                      <th width="140">Precio</th>
                    </tr>
                  </thead>

                  {data.routes.map((x, index) => {
                    return (
                      <tbody key={x.id} className="text-95 text-secondary-d3">
                        <tr>
                          <td>{index + 1}</td>
                          <td className="d-none d-md-table-cell">{x.creditor_id}</td>
                          <td>{x.route_name}</td>
                          <td>{x.location}</td>
                          <td className="text-secondary-d2">{printAmount(x.dueAmount)}</td>
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </div>

              <div className="row border-b-2 brc-default-l2"></div>

              <div className="row mt-3">
                <div className="col-12 col-sm-6 text-grey-d2 text-95 mt-2 mt-lg-0">
                  {data.invoice.paidAmount > payAmount && (
                    <p>
                      Tiene un credito de {printAmount(data.invoice.paidAmount - payAmount)} para su proxima factura!
                    </p>
                  )}
                  <p>Si no entiende por completo esta factura, por favor escribirme!</p>
                </div>

                <div className="col-12 col-sm-6 text-grey text-90 order-first order-sm-last">
                  <div className="row my-2">
                    <div className="col-7 text-right">Balance Actual</div>
                    <div className="col-5">
                      <span className="text-120 text-secondary-d1">{printAmount(currentBalance)}</span>
                    </div>
                  </div>

                  <div className="row my-2">
                    <div className="col-7 text-right">Balance Atrasado</div>
                    <div className="col-5">
                      <span className="text-110 text-secondary-d1">+{printAmount(data.invoice.prevBal)}</span>
                    </div>
                  </div>

                  <div className="row my-2">
                    <div className="col-7 text-right">Credito / Promo</div>
                    <div className="col-5">
                      <span className="text-110 text-secondary-d1">-{printAmount(data.invoice.paidCredit)}</span>
                    </div>
                  </div>

                  <div className="row my-2">
                    <div className="col-7 text-right">Pago de Referidos</div>
                    <div className="col-5">
                      <span className="text-110 text-secondary-d1">-{printAmount(data.invoice.paidReferral)}</span>
                    </div>
                  </div>

                  <div className="row my-2 align-items-center bgc-primary-l3 p-2">
                    <div className="col-7 text-right">Cantidad a Pagar</div>
                    <div className="col-5">
                      <span className="text-150 text-success-d3 opacity-2">
                        {printAmount(payAmount > 0 ? payAmount : 0)}
                      </span>
                    </div>
                  </div>

                  <div className="row my-2 text-primary font-weight-bold">
                    <div className="col-7 text-right">Pago Recibido</div>
                    <div className="col-5">
                      <span className="text-110 text-secondary-d1"> {printAmount(data.invoice.paidAmount)}</span>
                    </div>
                  </div>
                  <div className="row my-2 text-primary font-weight-bold">
                    <div className="col-7 text-right">Fecha Recibido</div>
                    <div className="col-5">
                      <span className="text-110 text-secondary-d1">
                        {" "}
                        {printDate(newDate(data.invoice.paymentDate))}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <hr />

              <div>
                <span className="text-secondary-d1 text-105">Gracias, por elegir trabajar con migo!</span>
                {/* <a href="#" className="btn btn-info btn-bold px-4 float-right mt-3 mt-lg-0">
          Pay Now
        </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenderInvoice;
