import { useContext, useState, useEffect, useCallback, useRef } from "react";
import { Context } from "../context/DataContext";
// import FloatingButton from "@shared/components/FloatingButton";
import SearchBox from "@shared/components/common/SearchBox";
import DebtorCard from "@shared/components/DebtorCard";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import { RemoveAccents, indentifySearchType } from "@shared/functions";
import { UseDebtors } from "@shared/server/fetcher";
import Loading from "@shared/components/Loading";
// import NoInternetContent from "@shared/components/NoInternetContent";
import FabDebtorCreate from "../components/FabDebtorCreate";
import useDebounce from "@shared/hooks/useDebounce";

const Debtors = ({ history }) => {
  const { state } = useContext(Context);
  const [searchText, setSearchText] = useState("");
  const [filteredDebtors, setFilteredDebtors] = useState([]);
  const { user, route } = state;
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

  const { data: debtors, isLoading } = UseDebtors(route.creditor_id, user.user_id);

  const loader = useRef(null);
  const debtorsXPage = 10;

  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setPage((prev) => prev + 1);
    }
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver]);

  useDebounce(
    () => {
      if (!debtors) return;
      if (searchText.length < 3) {
        setFilteredDebtors(debtors.slice(0, page * debtorsXPage));
        setLoading(false);
      } else {
        setFilteredDebtors(
          debtors
            .filter(
              (x) =>
                RemoveAccents(x.debtorSearch).toLowerCase().indexOf(indentifySearchType(searchText.toLowerCase())) > -1
            )
            .slice(0, debtorsXPage + 10)
        );
      }
    },
    [debtors, searchText, page],
    searchText.length < 3 ? 0 : 800
  );

  const handleSearch = (query) => {
    setSearchText(query);
  };

  return (
    <Container maxWidth="xl" disableGutters>
      {/* <FloatingButton icon={<PersonAddIcon />} tooltip="Agregar Cliente" goto="/debtor/create" history={history} /> */}
      <FabDebtorCreate history={history} />

      <div style={{ minHeight: "110%" }}>
        {debtors?.length === 0 ? (
          <div className="container mt-5 mb-5">
            <Typography variant="h5" componet="h3" gutterBottom>
              No hay clientes registrado!
            </Typography>
            <Typography variant="body1" componet="p" paragraph>
              En esta pagina puede ver todo los clientes.
            </Typography>
            <Typography variant="body1" componet="p" paragraph>
              Por el momento, no tiene ningun cliente!
            </Typography>
          </div>
        ) : (
          <>
            {loading || isLoading ? (
              <Loading />
            ) : (
              <div className="mt-4 mb-5">
                <Box component="div" p={1}>
                  <Typography variant="h5" componet="h3">
                    Lista de Clientes ({debtors?.length})
                  </Typography>
                  <SearchBox onChange={handleSearch} placeHolder="Escriba para buscar clientes..." value={searchText} />
                </Box>

                <Grid container spacing={1}>
                  {filteredDebtors.map((debtor) => (
                    <Grid key={debtor.debtor_id} item xs={12} sm={10} md={6} lg={4} xl={3}>
                      <DebtorCard history={history} debtor={debtor} />
                    </Grid>
                  ))}
                </Grid>
              </div>
            )}
          </>
        )}
      </div>
      <Box ref={loader} component="div" p={2}>
        {!loading && searchText.length < 3 && debtors?.length > page * debtorsXPage && (
          <Typography variant="h5" paragraph>
            Por favor espere...
          </Typography>
        )}
      </Box>
    </Container>
  );
};

export default Debtors;
