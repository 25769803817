import DateRangePicker from "@nodes/@wojtekmaj/react-daterange-picker";
import Box from "@nodes/@material-ui/core/Box";
import { printDate, newDate } from "../functions";

const DateRangePickerComponent = ({ setCurrentDate, route, date }) => {
  if (!date) return null;
  const handleChange = (pickedDate) => {
    if (!pickedDate[0]) return;
    setCurrentDate({
      start_date: printDate(pickedDate[0], "yyyy-MM-dd"),
      end_date: printDate(pickedDate[1], "yyyy-MM-dd"),
    });
  };

  return (
    <Box component="div" p={1}>
      <DateRangePicker
        onChange={handleChange}
        value={[newDate(date.start_date), newDate(date.end_date)]}
        clearIcon={null}
        calendarIcon={null}
        locale={route.route_location === "1" ? "en" : "es"}
        showLeadingZeros
      />
    </Box>
  );
};

export default DateRangePickerComponent;
