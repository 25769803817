import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { Context } from "../context/DataContext";
import Typography from "@material-ui/core/Typography";
import SubmitButton from "@shared/components/SubmitButton";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";
import { printAmount } from "@shared/functions";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { getPaymentSelector } from "../context/Selectors";
import Loading from "@shared/components/Loading";
import { UseLoanPayments, UseRoute } from "@shared/server/fetcher";
import { useSWRConfig } from "swr";
import RenderBackButton from "@shared/components/RenderBackButton";

const DeletePayment = ({ history }) => {
  const { mutate } = useSWRConfig();
  const { state, deletePayment } = useContext(Context);
  const [currentPayment, setCurrentPayment] = useState(null);
  const { id: payment_id, id2: money_id, id3: debtor_id } = useParams();
  const { user, route } = state;

  const { data: LoanPayments, isLoading, key } = UseLoanPayments(money_id, debtor_id);
  const { data: routeN, isLoading: routeLoading } = UseRoute(route.creditor_id, user.user_id);

  const handleDeletePayment = async () => {
    await deletePayment({
      debtor_id: currentPayment.debtor_id,
      mUniqueId: currentPayment.mUniqueId,
      pUniqueId: currentPayment.pUniqueId,
      user_id: user.user_id,
      isCobro: routeN.isCobro,
      creditor_id: routeN.creditor_id,
    });
    mutate(key);
    mutate(`/xcobrar/loans/${route.creditor_id}/${user.user_id}`);
    history.goBack();
  };

  useEffect(() => {
    if (LoanPayments) {
      setCurrentPayment(getPaymentSelector(LoanPayments, payment_id));
    }
    //eslint-disable-next-line
  }, [LoanPayments]);

  if (isLoading || routeLoading || !currentPayment) return <Loading />;

  return (
    <div className="container mt-5">
      <Card>
        <CardContent>
          <Typography variant="h5" component="h3" className="text-danger">
            Confirmaci&oacute;n
          </Typography>

          <Typography variant="h6" component="h4" gutterBottom>
            Decea borrar este pago?
          </Typography>
          <Typography variant="body1" component="p" paragraph>
            Pago de {printAmount(currentPayment.paymentAmount)} para {currentPayment.name}
          </Typography>

          <Box paddingTop={2}>
            <SubmitButton
              autoFetch={false}
              color="secondary"
              text="Borrar Pago!"
              startIcon={<DeleteForeverIcon />}
              callBack={handleDeletePayment}
            />
            <div className="mt-4">
              <RenderBackButton />
            </div>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

export default DeletePayment;
