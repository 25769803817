import { useContext, useEffect, useState } from "react";
import { Context } from "../context/DataContext";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Paper from "@material-ui/core/Paper";
import ListItemText from "@material-ui/core/ListItemText";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import DragHandleIcon from "@material-ui/icons/DragHandle";
// import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import { UseDebtorsReorder } from "@shared/server/fetcher";
import Loading from "@shared/components/Loading";
import { useSWRConfig } from "swr";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const DebtorsOrganize = () => {
  const { mutate } = useSWRConfig();
  const { state, setDebtorsReorderedMain } = useContext(Context);
  const { route, user } = state;
  const [currentDebtors, setCurrentDebtors] = useState([]);

  const { data: debtorsReorded, isLoading, mutate: mutate2 } = UseDebtorsReorder(route.creditor_id, user.user_id);

  useEffect(() => {
    mutate(`/debtors/organize/${route.creditor_id}/${user.user_id}`);
    if (!debtorsReorded) return;
    setCurrentDebtors(debtorsReorded);
    //eslint-disable-next-line
  }, [debtorsReorded]);

  if (isLoading) return <Loading />;

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const debtors = reorder(currentDebtors, result.source.index, result.destination.index);
    setCurrentDebtors(debtors);

    const debtorIds = debtors.map((loan) => loan.dUniqueId);
    console.log("*** Debtors re Organized ***", debtorIds);
    setDebtorsReorderedMain(debtorIds);

    mutate2();
    mutate(`/xcobrar/loans/${route.creditor_id}/${user.user_id}`);
  };

  if (currentDebtors?.length === 0)
    return (
      <div className="container mt-5 mb-5">
        <Typography variant="h5" componet="h3" gutterBottom>
          No hay clientes registrado!
        </Typography>
        <Typography variant="body1" componet="p" paragraph>
          En esta pagina puede ver orgnizar el orden de cobro.
        </Typography>
        <Typography variant="body1" componet="p" paragraph>
          Por el momento, no tiene ningun cliente!
        </Typography>
      </div>
    );

  return (
    <div className="container mt-3 mb-5">
      <Typography variant="h5" component="h3" gutterBottom>
        Ordenar Clientes
      </Typography>
      <Typography variant="body1" component="p" paragraph>
        En esta pagina puede organizar los clientes en el mismo orden en cual usted le cobra.
      </Typography>

      <Paper variant="outlined" style={{ backgroundColor: "#faf8f8" }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <List>
            <Droppable droppableId="droppable-1">
              {(provided, _) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {currentDebtors.map((debtor, i) => (
                    <Draggable key={debtor.debtor_id} draggableId={"draggable-" + debtor.debtor_id} index={i}>
                      {(provided, snapshot) => (
                        <ListItem
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={{
                            ...provided.draggableProps.style,
                            boxShadow: snapshot.isDragging ? "0 0 .5rem #e60d0f" : "none",
                          }}>
                          <ListItemIcon {...provided.dragHandleProps}>
                            <TouchAppIcon />
                          </ListItemIcon>
                          <ListItemText primary={debtor.name} />
                        </ListItem>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </List>
        </DragDropContext>
      </Paper>
    </div>
  );
};

export default DebtorsOrganize;
