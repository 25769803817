import { useContext } from "react";
import { Context } from "../context/DataContext";
import { useParams } from "react-router";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Box from "@material-ui/core/Box";
import { mixed, addMethod, string, object } from "yup";
import { Form, RenderInput } from "@shared/components/Form";
import { validateCedula } from "@shared/functions";
import { UseUser } from "@shared/server/fetcher";
import Loading from "@shared/components/Loading";
import Typography from "@material-ui/core/Typography";
import RenderBackButton from "@shared/components/RenderBackButton";

const UserPersonal = ({ history }) => {
  const { id: user_id, t: type } = useParams();
  const { state, updatePersonalInfo } = useContext(Context);
  const { user: user2 } = state;

  const { data: user, isLoading, mutate } = UseUser(user_id);

  if (isLoading) return <Loading />;

  const initialPersonalValues = {
    user_id: user.user_id,
    fname: user.fname,
    lname: user.lname,
    cedula: user.cedula,
    phone: user.phone,
    email: user.email,
    oldPhone: user.phone,
  };

  addMethod(mixed, "validateCedula", validateCedula);
  const validationPersonal = object({
    fname: string()
      .required("Nombre es requerido!")
      .min(3, "Nombre es requerido")
      .matches(/^([^0-9]*)$/, "Nombre no puede tener numeros!"),
    lname: string()
      .required("Apellido es requerido!")
      .min(3, "Apellido es requerido")
      .matches(/^([^0-9]*)$/, "Apellido no puede tener numeros!"),
    phone: string().required("Telefono es requerido").min(10, "Telefono invalido"),
    cedula: mixed().validateCedula(),
    email: string().email("Correo Electronico Invalido").max(35, "Correo Electronico es muy largo!"),
  });

  const buttonPersonal = {
    label: "Actualizar Informacion",
    variant: "contained",
    color: "primary",
  };

  const submithandlerPersonal = async (data) => {
    await mutate(updatePersonalInfo(data, history.replace, type), {
      populateCache: true,
    });
  };

  if (user2.user_id === 0) return null;

  return (
    <div className="mt-5">
      <Card raised>
        <CardHeader title="Informacion Personal" />
        <CardContent>
          <Typography variant="body1" paragraph>
            En esta pagina puede cambiar la Informaci&oacute;n personal!
          </Typography>
          <div>
            <Form
              initialValues={initialPersonalValues}
              validation={validationPersonal}
              submithandler={submithandlerPersonal}
              button={buttonPersonal}>
              <Box paddingBottom={2}>{RenderInput("fname", "Nombre")}</Box>
              <Box paddingBottom={2}>{RenderInput("lname", "Apellido")}</Box>
              <Box paddingBottom={2}>{RenderInput("phone", "Telefono", "tel")}</Box>
              <Box paddingBottom={2}>{RenderInput("email", "Correo")}</Box>
              <Box paddingBottom={4}>{RenderInput("cedula", "Cedula", "tel")}</Box>
            </Form>
            <div className="mt-4">
              <RenderBackButton />
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default UserPersonal;
