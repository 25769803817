
import Typography from "@nodes/@material-ui/core/Typography";
import Grid from "@nodes/@material-ui/core/Grid";
import Card from "@nodes/@material-ui/core/Card";
import CardContent from "@nodes/@material-ui/core/CardContent";
import { printAmount, printDate, newDate } from "../functions";
import { addMonths } from "@nodes/date-fns";
import { referralsSignUpMonths } from "../constants";

const RenderReferrals = ({ referrals = [] }) => {
  if (referrals.length === 0) {
    return (
      <div className="mt-4 container">
        <Typography variant="h5" component="h3">
          Por el momento, ningun prestamista sea registrado con su enlances de invitacion.
        </Typography>
      </div>
    );
  }
  return (
    <div>
      <Typography variant="h5" className="mt-3" gutterBottom>
        Prestamistas referido por mi!
      </Typography>

      <Grid container spacing={1}>
        {referrals.map((referral) => (
          <Grid item xs={12} sm={9} md={6} key={referral.referral_id}>
            <Card raised>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Typography variant="h6" component="h3" noWrap>
                      {referral.nickname}
                    </Typography>
                  </Grid>
                  <Grid align="right" item xs={4}>
                    <Typography variant="body1" component="h3">
                      {referral.country} {printAmount(referral.referral_amount)}
                    </Typography>
                  </Grid>

                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="body1" gutterBottom>
                        Fecha de Registro
                        <br />
                        <i> {printDate(newDate(referral.invited_date), "dd-MMM-y")}</i>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} align="right">
                      {referral.paid_date ? (
                        <span className="badge bg-success text-dark">
                          <Typography variant="body1">Pagado</Typography>
                        </span>
                      ) : referral.approved === "0" ? (
                        <span className="badge bg-danger text-dark">
                          <Typography variant="body1">Rechazado</Typography>
                        </span>
                      ) : (
                        <span className="badge bg-warning text-dark">
                          <Typography variant="body1">Pendiente</Typography>
                        </span>
                      )}
                    </Grid>
                  </Grid>
                  {referral.paid_date ? (
                    <Typography className="text-info" variant="subtitle1" component="p">
                      Pago de {printAmount(referral.referral_amount)} en {printDate(newDate(referral.paid_date))}
                    </Typography>
                  ) : (
                    <Typography className="text-info" variant="subtitle1" component="p">
                      Fecha estimada de pago{" "}
                      {printDate(addMonths(newDate(referral.invited_date), referralsSignUpMonths))}!
                    </Typography>
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default RenderReferrals;
