import { useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Checkbox from "@material-ui/core/Checkbox";

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const RenderGreenCheckbox = ({ user, route, handleToggleChange }) => {
  const [isLoading, setLoading] = useState(false);
  const handleClick = async (user, route) => {
    setLoading(true);
    await handleToggleChange(user, route);
    setLoading(false);
  };
  return (
    <>
      {isLoading ? (
        <CircularProgress size="1.5rem" />
      ) : (
        <>
          <GreenCheckbox
            size="medium"
            checked={user.is_active === "1" ? true : false}
            onChange={() => handleClick(user, route)}
            name="checkedG"
          />
        </>
      )}
    </>
  );
};

export default RenderGreenCheckbox;
