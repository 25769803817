import React from "react";
import { useContext } from "react";
import { Context } from "../context/DataContext";
import Snacky from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const Snackbar = () => {
  const classes = useStyles();
  const { state, dispatchAction } = useContext(Context);
  const { snackbar } = state;

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    //Default Snackbar propertys.
    dispatchAction("setSnackbar", { open: false, message: "" });
  };

  return (
    <div className={classes.root}>
      <Snacky open={snackbar.open} autoHideDuration={snackbar.duration} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbar.type}>
          {snackbar.message}!
        </Alert>
      </Snacky>
    </div>
  );
};

export default Snackbar;
