import { init as initialize, setUser, Replay } from "@sentry/browser";
// import LogRocket from "logrocket";
// import setupLogRocketReact from "logrocket-react";
import { BrowserTracing } from "@sentry/tracing";
import { toast } from "react-toastify";
import { localStorageKey, localStorageToken } from "./config";

let token = localStorage.getItem(localStorageToken);
if (!token) {
  token = Date.now() + Math.random().toString(36);
  localStorage.setItem(localStorageToken, token);
}

function init() {
  initialize({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: `main-${import.meta.env.VITE_VERSION}-${import.meta.env.VITE_ENV}`,
    token,
    environment: import.meta.env.VITE_ENV,

    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0.2,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [
      new BrowserTracing(),
      new Replay({
        maskAllText: false,
        blockAllMedia: true,
      }),
    ],
  });

  // LogRocket.init("9dwevc/wbo-main-j5qyl", {
  //   release,
  // });

  // setupLogRocketReact(LogRocket);
}

function identifyUser() {
  const user = getLocalData(localStorageKey)?.device;

  if (user) {
    console.log("Itenfied user as ", user);
    // LogRocket.identify(user.user_id, {
    //   name: user.firstname + " " + user.lastname,
    // });
    setUser({
      id: user.user_id,
      username: user.username,
      name: user.firstname + " " + user.lastname,
    });
  }
}

function getLocalData(key) {
  try {
    const jsonValue = localStorage.getItem(key);
    const data = jsonValue != null ? JSON.parse(jsonValue) : null;

    return data;
  } catch (e) {
    return null;
  }
}

function spanishErrors(error) {
  let $arr = [];
  $arr["Network Error"] = "Error Conexion de Internet!";
  $arr["timeout of 30000ms exceeded"] = "Tiempo de espera de 30 segundos excedido!";
  $arr["timeout of 60000ms exceeded"] = "Tiempo de espera de 60 segundos excedido!";
  $arr["timeout of 90000ms exceeded"] = "Tiempo de espera de 90 segundos excedido!";

  return $arr[error] ? $arr[error] : error;
}

function log(message) {
  console.log(message);
  // sentryLog(message);
}

function error(message) {
  console.error(message);
  toast.error(spanishErrors(message), { hideProgressBar: true, autoClose: 8000 });
  // sentryLog(message);
}

// function sentryLog(message) {
//   if (isProduction) {
//     Sentry.captureException(message);
//   }
// }

const exportDefault = { init, log, error, identifyUser };
export default exportDefault;
