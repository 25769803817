import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Form, RenderInput } from "@shared/components/Form";
import { number, string, object } from "yup";
import CreateIcon from "@material-ui/icons/Create";
import { printAbsAmount } from "@shared/functions";

const UpdateEfectivoAmount = ({ data, cashMoney, handleEfectivoUpdate }) => {
  const [showModal, setShowModal] = useState(false);
  if (!data) return null;

  const initialValues = {
    creditor_id: data.creditor_id,
    current_week: data.today_date,
    actualCash: data.actualCash === "0" ? "" : data.actualCash,
    note: data.note,
  };

  const validation = object({
    actualCash: number().required("Cantidad es requerida!").max(999999, "Cantidad es muy alta!"),
    note: string().max(60, "La Nota es muy alta!"),
  });

  const button = { label: "Actualizar", variant: "contained", color: "primary" };

  const submithandler = async (data) => {
    await handleEfectivoUpdate(data);
    setShowModal(false);
  };

  const actualCash = data.actualCash * 1;

  return (
    <>
      <Card>
        <CardContent>
          <b>
            {cashMoney >= 0 ? (
              <span>( = ) Dinero en Efectivo: {printAbsAmount(cashMoney)}</span>
            ) : (
              <span className="text-danger">( = ) Dinero en Efectivo: {printAbsAmount(cashMoney)} (Negativo)</span>
            )}
          </b>
          <span className="text-warning ml-1" onClick={() => setShowModal(!showModal.show)}>
            <CreateIcon />
          </span>

          {actualCash !== 0 && (
            <Typography
              variant="subtitle2"
              className={cashMoney > actualCash ? "pl-2 text-danger" : "pl-2 text-success"}>
              Diferencia de efectivo {printAbsAmount(cashMoney - actualCash)}
            </Typography>
          )}

          {data.note && (
            <div className="alert alert-light text-bold">
              <Typography variant="body1">
                <b>{data.note}</b>
              </Typography>
            </div>
          )}
        </CardContent>
      </Card>

      <Modal show={showModal} onHide={() => setShowModal(false)} backdrop="static" keyboard={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <Typography variant="h5">Modificar Efectivo</Typography>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container mb-4">
            <Typography variant="body1" paragraph>
              Escriba hacia abajo la cantidad correcta de Efectivo
            </Typography>

            <Form initialValues={initialValues} validation={validation} submithandler={submithandler} button={button}>
              <Box paddingBottom={3}>
                <Box paddingBottom={2}>
                  {RenderInput("actualCash", "Cantidad de Efectivo", "number", true, "outlined")}
                </Box>
                <Box paddingBottom={1}>{RenderInput("note", "Descripcion (Opcional)", "text", false, "outlined")}</Box>
              </Box>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UpdateEfectivoAmount;
