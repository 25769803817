import { useContext } from "react";
import { Context } from "../context/DataContext";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Redirect } from "react-router-dom";
import PhoneVerify from "@shared/components/PhoneVerify";

const UserPhoneVerify = ({ history }) => {
  const { state, createVerifyPhone, phoneVerificationPwReset } = useContext(Context);

  if (!state.passwordReset) return <Redirect to="/" />;
  const submithandler = (data) => phoneVerificationPwReset(data, history.replace);
  return (
    <div className="container mt-4 mb-5">
      <PhoneVerify submithandler={submithandler} createVerifyPhone={createVerifyPhone} userData={state.passwordReset} />

      <Box component="div" paddingTop={5}>
        <Button fullWidth variant="outlined" color="primary" onClick={() => history.replace("/password/resetmethod")}>
          Elegir metodo de verificacion!
        </Button>
      </Box>
    </div>
  );
};

export default UserPhoneVerify;
