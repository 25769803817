import React from "react";
import Typography from "@material-ui/core/Typography";
import { printDate, newDate } from "@shared/functions";

const AperturaCard = ({ apertura, changeApertura, disableDesc = false }) => {
  const validateChangeApertura = (week_date, apertura_date) => {
    if (week_date === apertura_date) return;
    changeApertura(week_date, apertura_date);
  };

  return (
    <div>
      <div className="jumbotron">
        <Typography variant="h6" gutterBottom>
          Cobro de {printDate(newDate(apertura.week_date), "MMMM dd, y")}!
        </Typography>

        {!disableDesc && (
          <Typography variant="body1" gutterBottom>
            Puede hacer clic en los siguientes botones para hacer cambios a la apertura de Cobro de{" "}
            {printDate(newDate(apertura.week_date), "MMMM dd, y")}!.
          </Typography>
        )}

        <hr className="my-3" />

        <div className="btn-group  btn-group-sm" role="group" aria-label="Basic example">
          {apertura.days.map((item) => (
            <button
              onClick={() => validateChangeApertura(apertura.week_date, item.day)}
              key={item.id}
              type="button"
              className={
                item.day === apertura.week_date
                  ? "btn btn-dark disabled"
                  : item.selected === true
                  ? "btn btn-success"
                  : "btn btn-outline-dark"
              }>
              {printDate(newDate(item.day), "MMM dd")}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AperturaCard;
