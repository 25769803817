import { useContext } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { string, object } from "yup";
import { Redirect } from "react-router-dom";
import { Form, RenderInput } from "@shared/components/Form";
import { Context } from "../context/DataContext";

const SecurityVerify = ({ history }) => {
  const { state, verifySecurityQuestions } = useContext(Context);

  if (!state.passwordReset) return <Redirect to="/" />;

  const initialValues = {
    user_id: state.passwordReset.user_id,
    username: state.passwordReset.username,
    salt1: state.passwordReset.salt1,
    question_id1: state.passwordReset.question_id1,
    name: state.passwordReset.name,
    phone: state.passwordReset.phone,
    answer1: "",
  };

  const validation = object({
    answer1: string().required("Repuesta es requerido!"),
  });

  const button = { label: "Continuar", variant: "contained", color: "primary" };

  const submithandler = (data) => verifySecurityQuestions(data, history.replace);

  return (
    <div className="container mt-4 mb-5">
      <Typography variant="h5" component="h3" gutterBottom>
        Preguntas de Seguridad!
      </Typography>

      <Typography variant="subtitle1" component="p" paragraph>
        Antes de permitir le, elegir una nueva contraseña. Conteste correctamente la siguiente preguntas!
      </Typography>
      <Form initialValues={initialValues} validation={validation} submithandler={submithandler} button={button}>
        <Box paddingBottom={1}>
          <Typography variant="h6" component="h4" gutterBottom>
            {state.passwordReset.question1}
          </Typography>
        </Box>
        <Box paddingBottom={3}>{RenderInput("answer1", "Repuesta Secreta", "password")}</Box>
      </Form>

      <Box component="div" paddingTop={5}>
        <Button fullWidth variant="outlined" color="primary" onClick={() => history.replace("/password/resetmethod")}>
          Elegir metodo de verificacion!
        </Button>
      </Box>
    </div>
  );
};

export default SecurityVerify;
