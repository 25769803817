import { useContext } from "react";
import { useParams } from "react-router";
import { Context } from "../context/DataContext";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { UseUserLinkedRoutes } from "@shared/server/fetcher";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { string, object } from "yup";
import { Form, RenderInput } from "@shared/components/Form";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import RenderBackButton from "@shared/components/RenderBackButton";

const DeleteRoute = ({ history }) => {
  const { deleteRoute } = useContext(Context);

  const { id: creditor_id, id2: user_id } = useParams();
  // const { data: routeN, isLoading: routeLoading } = UseRoute(route.creditor_id, user.user_id);
  const { mutate } = UseUserLinkedRoutes(user_id);

  // if (isLoading ) return <Loading />;

  const initialPersonalValues = {
    user_id,
    creditor_id,
    password: "",
  };

  const validationPersonal = object({
    password: string().required("Nombre es requerido!"),
  });

  const buttonPersonal = {
    icon: <DeleteForeverIcon />,
    label: "Borrar Ruta",
    variant: "contained",
    color: "primary",
  };

  const submitHandlder = async (data) => {
    await mutate(deleteRoute(data, history.replace), {
      populateCache: true,
    });
  };

  return (
    <div className="container mt-5">
      <Card>
        <CardContent>
          <Typography variant="h5" component="h3" className="text-danger" gutterBottom>
            Confirmaci&oacute;n
          </Typography>

          <Typography variant="h6" component="h4" gutterBottom>
            Decea borrar este Ruta?
          </Typography>

          <Typography variant="body1" component="p" paragraph>
            Despues de ser borrada, usted perder&aacute; el Acceso a esta ruta.
          </Typography>

          <Form
            initialValues={initialPersonalValues}
            validation={validationPersonal}
            submithandler={submitHandlder}
            button={buttonPersonal}>
            <Typography variant="body1">Para poder borrar la ruta, tiene que verificar su contraseña.</Typography>
            <Box paddingTop={3} paddingBottom={4}>
              {RenderInput("password", "Contraseña", "password")}
            </Box>
          </Form>
          <div className="mt-4">
            <RenderBackButton />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default DeleteRoute;
