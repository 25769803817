import { useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// import MoreVertIcon from "@material-ui/icons/MoreVert";
// import EditIcon from "@material-ui/icons/Edit";
// import DeleteIcon from "@material-ui/icons/Delete";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import { FaUserEdit, FaUserCog } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";
import { MdOutlinePassword } from "react-icons/md";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

export default function EmployeeMenu({ history, user_id, owner_id }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton aria-label="settings" onClick={handleClick}>
        <FaUserCog />
      </IconButton>

      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => history.push(`/user/personal/${user_id}/employee`)}>
          <FaUserEdit size="1.6em" className="pr-1" />
          Cambiar Informaci&oacute;n
        </MenuItem>
        <Divider />

        <MenuItem onClick={() => history.push(`/user/username/${user_id}/employee`)}>
          <RiLockPasswordFill size="1.6em" className="pr-1" />
          Cambiar Usuario
        </MenuItem>
        <Divider />

        <MenuItem onClick={() => history.push(`/user/password/${user_id}/employee`)}>
          <MdOutlinePassword size="1.6em" className="pr-1" />
          Cambiar Contrase&ntilde;a
        </MenuItem>

        <MenuItem className="text-danger" onClick={() => history.push(`/employee/delete/${owner_id}/${user_id}`)}>
          <DeleteForeverIcon className="pr-1" />
          Eliminar Empleado
        </MenuItem>
      </Menu>
    </div>
  );
}
