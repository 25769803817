import { useContext } from "react";
import { Context } from "../context/DataContext";
import { useParams } from "react-router";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";
import Grow from "@material-ui/core/Grow";
import DebtorNameHeader from "@shared/components/DebtorNameHeader";
import RenderLoanDetail from "@shared/components/RenderLoanDetail";
import RenderLoanDetailButtons from "@shared/components/RenderLoanDetailButtons";
// import { printAmount } from "@shared/functions";
import DisplayLoanStatus from "@shared/components/DisplayLoanStatus";
import DisplayLastPaymentAlert from "@shared/components/DisplayLastPaymentAlert";
import LoanDetailMenu from "@shared/components/LoanDetailMenu";
import Loading from "@shared/components/Loading";
import { UseLoan, UseRoute } from "@shared/server/fetcher";
import NoInternetContent from "@shared/components/NoInternetContent";
import LoanPinned from "../components/LoanPinned";

const LoanDetail = ({ history }) => {
  const { id: money_id, id2: debtor_id } = useParams();
  const { state, toggleLoanPin } = useContext(Context);
  const { user, route } = state;

  const { data: routeN, isLoading: routeLoading } = UseRoute(route.creditor_id, user.user_id);
  const { data: loan, isLoading, mutate } = UseLoan(money_id, debtor_id, route.creditor_id, user.user_id);

  if (isLoading || routeLoading) return <Loading />;
  if (!loan) return <NoInternetContent />;

  return (
    <div className="mt-3 mb-5">
      <Container maxWidth="md" disableGutters>
        <DebtorNameHeader debtor={loan}>
          <div className="" style={{ display: "inline-flex" }}>
            <LoanPinned loan={{ ...routeN, ...loan }} toggleLoanPin={toggleLoanPin} mutate={mutate} />

            <LoanDetailMenu history={history} loan={loan} />
          </div>
        </DebtorNameHeader>

        {loan.is_active === "0" && (
          <Box component="div" p={1}>
            <Grow direction="up" in>
              <Alert variant="outlined" severity="error">
                <Typography variant="body1" component="p" className="font-weight-bold">
                  Este prestamo fue cancelado!
                </Typography>
              </Alert>
            </Grow>
          </Box>
        )}

        {loan.paidFromRenewal === true && (
          <Box component="div">
            <div className="alert alert-primary">
              <Typography variant="subtitle2" component="p">
                Este prestamo fue saldado con un renovo!
              </Typography>
            </div>
          </Box>
        )}

        <DisplayLastPaymentAlert loan={loan} />

        {/* {loan.balance > 0 && ( */}
        <Box component="div" p={1}>
          <DisplayLoanStatus loan={loan} />
        </Box>
        {/* )} */}

        <Box component="div" p={1}>
          <RenderLoanDetail loan={loan} showLoanPin />
          <RenderLoanDetailButtons loan={loan} history={history} />
        </Box>
      </Container>
    </div>
  );
};

export default LoanDetail;
