import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Grow from "@material-ui/core/Grow";

const RenderUserRestriction = () => {
  return (
    <div>
      <Box component="div" p={1}>
        <Grow direction="up" in>
          <Alert variant="outlined" severity="error">
            <Typography variant="body1" component="p" className="font-weight-bold">
              No Autorizado para ver esta pagina
            </Typography>
          </Alert>
        </Grow>
      </Box>
    </div>
  );
};

export default RenderUserRestriction;
