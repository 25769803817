import React from "react";
import HomeIcon from "@material-ui/icons/Home";
import PeopleIcon from "@material-ui/icons/People";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import SettingsIcon from "@material-ui/icons/Settings";
// import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
// import ThumbUpIcon from "@material-ui/icons/ThumbUp";
// import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import { FaHandHoldingUsd } from "react-icons/fa";
import { HiClipboardList } from "react-icons/hi";
import { GiPayMoney, GiTakeMyMoney } from "react-icons/gi";
import { MdScreenSearchDesktop } from "react-icons/md";
import { BsPersonLock } from "react-icons/bs";
import { HiDocumentReport } from "react-icons/hi";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";

export const DrawerList = [
  {
    title: "Inicio",
    path: "/dashboard",
    icon: <HomeIcon />,
  },
  {
    title: "Data Credito",
    path: "/data/credito",
    icon: <MdScreenSearchDesktop size="1.5em" />,
  },
  {
    title: "Cobro Lista",
    path: "/xcobrar/list",
    icon: <HiClipboardList size="1.5em" />,
  },
  {
    title: "Prestamos",
    path: "/loans",
    icon: <FaHandHoldingUsd size="1.5em" />,
  },
  {
    title: "Clientes",
    path: "/debtors",
    icon: <PeopleIcon />,
  },
  {
    title: "Cobrado",
    path: "/collected",
    icon: <GiTakeMyMoney size="1.7em" />,
  },
  {
    title: "Entregas",
    path: "/entregas",
    icon: <GiPayMoney size="1.5em" />,
  },
  {
    title: "Gastos",
    path: "/spenses",
    icon: <LocalOfferIcon />,
  },
  {
    title: "Cuadre",
    path: "/cuadre",
    icon: <MonetizationOnIcon />,
  },
  // {
  //   title: "Caja",
  //   path: "/caja",
  //   icon: <AccountBalanceIcon />,
  // },
  // {
  //   title: "Solicitudes",
  //   path: "/approval",
  //   icon: <ThumbUpIcon />,
  // },
  {
    title: "Reportes",
    path: "/reports",
    icon: <HiDocumentReport size="1.7em" />,
  },
  {
    title: "Empleados",
    path: "/employees",
    icon: <PeopleOutlineIcon />,
  },
  {
    title: "Codigo Acceso",
    path: "/access/codes",
    icon: <BsPersonLock size="1.5em" />,
  },
  {
    title: "Ajustes",
    path: "/settings",
    icon: <SettingsIcon />,
  },
];
