import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { useDropzone } from "react-dropzone";
import RootRef from "@material-ui/core/RootRef";
import { makeStyles } from "@material-ui/core/styles";
import CropImage from "@shared/components/CropImage";
import { Context } from "../context/DataContext";
import { getDebtorSelector } from "../context/Selectors";
import Loading from "@shared/components/Loading";
import SubmitButton from "@shared/components/SubmitButton";
// import CancelIcon from "@material-ui/icons/Cancel";
import { MdSave } from "react-icons/md";
import { UseDebtors } from "@shared/server/fetcher";

const useStyles = makeStyles((theme) => ({
  dropzoneContainer: {
    height: 80,
    width: "100%",
    background: "#efefef",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderStyle: "dashed",
    borderColor: "#aaa",
  },
  preview: {
    width: "95%",
    // height: "70%",
    margin: "auto",
    display: "block",
    marginBottom: theme.spacing(2),
    objectFit: "cover",
  },
}));

function UploadImage({ history }) {
  const { id: dUniqueId, page } = useParams();
  const classes = useStyles();
  // const [file, setFile] = useState();
  const [base64, setBase64] = useState();
  const { state, uploadDebtorImage } = useContext(Context);
  const { route, user } = state;
  const [preview, setPreview] = useState();
  const [selectedImageFile, setSelectedImageFile] = useState();
  // const [currentDebtor, setCurrentDebtor] = useState(null);

  const { data: debtors, isLoading: debtorLoading } = UseDebtors(route.creditor_id, user.user_id);
  const currentDebtor = debtors?.find((x) => x.dUniqueId === dUniqueId);

  // useEffect(() => {
  //   if (user.user_id === 0 || !debtors) return;
  //   setCurrentDebtor(getDebtorSelector(debtors, debtor_id));
  //   //eslint-disable-next-line
  // }, [debtors]);

  const onDrop = (acceptedFiles) => {
    const fileDropped = acceptedFiles[0];
    if (fileDropped["type"].split("/")[0] === "image") {
      setSelectedImageFile(fileDropped);
      return;
    }

    if (!fileDropped) return;

    // setFile(fileDropped);
    const previewUrl = URL.createObjectURL(fileDropped);
    setPreview(previewUrl);
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop,
  });

  const { ref, ...rootProps } = getRootProps();

  const onCropSave = ({ file, preview, base64 }) => {
    setPreview(preview);
    // setFile(file);
    setBase64(base64);
  };

  if (!currentDebtor || debtorLoading) return <Loading />;

  const redirectTo = page ? `/debtor/profile/${dUniqueId}` : `/redirect/page/${dUniqueId}/0`;

  const handleAppendFile = async () => {
    await uploadDebtorImage({
      data: base64,
      timestamp: Date.now(),
      debtor_id: currentDebtor.debtor_id,
      cedula: currentDebtor.cedula,
      dUniqueId: currentDebtor.dUniqueId,
      creditor_id: currentDebtor.creditor_id,
      new_debtor: currentDebtor.new_debtor,
    });

    history.replace(redirectTo);
  };

  return (
    <>
      <Paper elevation={4}>
        <Grid container>
          <Grid item xs={12}>
            <Typography align="center" style={{ padding: 16 }}>
              Foto de Indentificacion
            </Typography>
            <Typography variant="h6" align="center" gutterBottom>
              {currentDebtor.name}
            </Typography>
            <Divider />
          </Grid>

          <Grid item xs={12} style={{ paddingTop: 10 }}>
            <RootRef rootRef={ref}>
              <Paper {...rootProps} elevation={0} className={classes.dropzoneContainer}>
                <input {...getInputProps()} />
                <Typography variant="h6" className="m-1">
                  Presione aqui para elegir una foto.
                </Typography>
              </Paper>
            </RootRef>
          </Grid>

          <Grid item xs={12} style={{ paddingTop: 10 }}>
            <Typography align="center" variant="h6">
              Vista Previa
            </Typography>
            <img
              id="canvas"
              onLoad={() => URL.revokeObjectURL(preview)}
              className={classes.preview}
              alt="Preview"
              src={preview || "/../../images/placeholder350x150.png"}
            />
          </Grid>
        </Grid>
      </Paper>

      <CropImage onSave={onCropSave} selectedFile={selectedImageFile} />

      <Box marginBottom={3} p={2}>
        {/* <Button
          disabled={preview ? false : true}
          onClick={handleAppendFile}
          fullWidth
          variant="outlined"
          color="primary">
          Guardar Foto!
        </Button> */}

        {preview && (
          <SubmitButton
            autoFetch={false}
            color="primary"
            text="Guardar Foto!"
            startIcon={<MdSave />}
            callBack={handleAppendFile}
          />
        )}
      </Box>

      {!preview && (
        <Box p={2}>
          <Link to={redirectTo}>
            <Button fullWidth color="primary">
              Continuar sin Foto!
            </Button>
          </Link>
        </Box>
      )}
    </>
  );
}

export default UploadImage;
