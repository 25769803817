import { useContext } from "react";
import { useParams } from "react-router";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import { Context } from "../context/DataContext";
import FavoriteDebtor from "../components/FavoriteDebtor";
import { FaHandHoldingUsd } from "react-icons/fa";
import DebtorNameHeader from "@shared/components/DebtorNameHeader";
import RenderDebtorLoans from "@shared/components/RenderDebtorLoans";
// import RenderDebtorImages from "@shared/components/RenderDebtorImages";
import RenderDebtorProfile from "@shared/components/RenderDebtorProfile";
import DebtorMenu from "@shared/components/DebtorMenu";
import Loading from "@shared/components/Loading";
import { UseDebtors, UseDebtorLoans } from "@shared/server/fetcher";
import NoInternetContent from "@shared/components/NoInternetContent";

const LoanCreate = ({ history }) => {
  const { id: dUniqueId } = useParams();
  const { state, toggleFaveDebtor } = useContext(Context);
  const { route: stateRoute, user } = state;

  // const { data: route, isLoading: routeLoading } = UseRoute(stateRoute.creditor_id, user.user_id);
  // const { data: debtor, isLoading: isLoadingDebtor } = UseDebtor(debtor_id, user.user_id);
  const { data: debtors, isLoading: debtorLoading, mutate } = UseDebtors(stateRoute.creditor_id, user.user_id);
  const debtor = debtors?.find((x) => x.dUniqueId === dUniqueId);
  const { data: loans, isLoading: isLoadingLoans } = UseDebtorLoans(
    debtor?.debtor_id,
    stateRoute.creditor_id,
    user.user_id
  );

  if (debtorLoading) return <Loading />;
  if (!debtors) return <NoInternetContent />;

  return (
    <div className="mt-2 mb-5">
      <Container maxWidth="md" disableGutters>
        <DebtorNameHeader debtor={debtor}>
          <div className="" style={{ display: "inline-flex" }}>
            <FavoriteDebtor debtor={debtor} toggleFaveDebtor={toggleFaveDebtor} mutate={mutate} />

            <DebtorMenu history={history} debtor={debtor} profile />
          </div>
        </DebtorNameHeader>

        {debtor?.is_active === "0" && (
          <Box component="div">
            <div className="alert alert-danger">
              <Typography variant="subtitle2" component="p">
                Este cliente fue eliminado!
              </Typography>
            </div>
          </Box>
        )}

        {loans?.length === 0 && (
          <div>
            <Box component="div">
              <div className="alert alert-primary">
                <Typography variant="subtitle2" component="p">
                  Este Cliente no tiene prestamos con balance!
                </Typography>
              </div>
            </Box>
            <Box paddingBottom={2}>
              <Button
                fullWidth
                startIcon={<FaHandHoldingUsd size="1.2em" />}
                variant="outlined"
                color="default"
                onClick={() => history.push(`/redirect/page/${debtor.dUniqueId}/0/${debtor.cedula}`)}>
                Nuevo Prestamo!
              </Button>
            </Box>
          </div>
        )}

        <Box component="div" p={1}>
          <RenderDebtorProfile debtor={debtor} />
        </Box>

        {isLoadingLoans ? (
          <Loading />
        ) : (
          <Box component="div" p={1}>
            <RenderDebtorLoans history={history} loans={loans} />
          </Box>
        )}

        <Box paddingBottom={2}>
          <Button
            fullWidth
            className="text-info"
            startIcon={<FaHandHoldingUsd size="1.2em" />}
            variant="outlined"
            color="default"
            onClick={() => history.push("/loan/old/create/" + debtor.debtor_id)}>
            Prestamo Antiguo!
          </Button>
        </Box>
      </Container>
    </div>
  );
};

export default LoanCreate;
